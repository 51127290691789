
export default {
  name: 'FollowUpCount',
  data() {
    return {
      count: 0,
    }
  },
  async mounted() {
    const { result, success } =
      await this.$repository.v3.admission.applicants.followUp.getTotalFollowup()

    if (success) {
      this.count = result.data
    }
  },
}
