import Cookies from 'js-cookie'
import { datadogLogs } from '@datadog/browser-logs'

export default async ({ store, route, redirect }) => {
  const { path } = route
  const meta = route.meta[0] || {}

  if (path === '/logout/') {
    if (datadogLogs.getUser()) {
      datadogLogs.clearUser()
    }

    await store.commit('auth/REMOVE_TOKEN')
    return redirect(`/login/`)
  }

  const tokenStorage = Cookies.get('auth.office.token')

  if (!tokenStorage) {
    if (datadogLogs.getUser()) {
      datadogLogs.clearUser()
    }

    if (meta.auth === 'guest') return
    return redirect(`/login/`)
  }

  store.commit('auth/ADD_TOKEN', tokenStorage)

  if (store.state.auth.user === null) {
    const { success } = await store.dispatch('auth/me')

    // Si falla mandamos al login
    if (!success) {
      store.commit('auth/REMOVE_TOKEN')
      if (datadogLogs.getUser()) {
        datadogLogs.clearUser()
      }

      return redirect(`/login/`)
    }
  }

  if (meta.auth === false) return

  if (meta.auth === 'guest') {
    return redirect(`/`)
  }
}
