import { useRequestOffice } from '~/repositories'

const resource = 'api/v3/partnership/business-users'
export default () => ({
  generateToken(businessUserId) {
    return useRequestOffice(
      'get',
      `${resource}/${businessUserId}/generate-token`
    )
  },
})
