const resource = '/mentor/application'

export default ($axios) => ({
  postGetTable(payload) {
    return $axios.post(`${resource}/table`, payload)
  },
  postGetTableUser(payload) {
    return $axios.post(`${resource}/user_table`, payload)
  },
  postGetKpis(payload) {
    return $axios.post(`${resource}/kpis`, payload)
  },
})
