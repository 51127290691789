import { notification } from 'ant-design-vue'

export const state = () => ({
  talent_id: null,
  deliverables: null,
  all_deliverables: {
    tableDeliveries: [],
    tableRevisions: [],
    paginationDeliveries: {},
    paginationRevisions: {},
    statuses: [],
  },
  filter_deliverables: null,
  deliverables_by_mentor: {},
  count_total_deliverables: 0,
  count_pending_deliverables: 0,
  count_pending_revisions: 0,
})

export const mutations = {
  SET_STATE_DELIVERABLE(state, { type, data }) {
    state[type] = data
  },
  SET_DELIVERABLE(state, payload) {
    const { status, index } = payload

    state.deliverables[index].user_deliverable.user_deliverable_status_id =
      status
  },
  SET_ALL_DELIVERABLES(state, payload) {
    const { type, data } = payload
    state.all_deliverables[type] = data
  },
  SET_DELIVERABLES_BY_MENTOR(state, payload) {
    const { data } = payload
    state.deliverables_by_mentor = data
  },
  SET_COUNT_TOTAL_DELIVERABLES(state, payload) {
    const { data } = payload
    state.count_total_deliverables = data
  },
  SET_COUNT_PENDING_DELIVERABLES(state, payload) {
    const { data } = payload
    state.count_pending_deliverables = data
  },
  SET_COUNT_PENDING_REVISIONS(state, payload) {
    const { data } = payload
    state.count_pending_revisions = data
  },
}

export const actions = {
  getDeliverables({ commit }, id) {
    return this.$repository.mentor.userProfile
      .postGetDeliverables({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_DELIVERABLE', {
          type: 'deliverables',
          data: data.data,
        })
        commit('SET_STATE_DELIVERABLE', {
          type: 'talent_id',
          data: id,
        })
        return data
      })
  },

  getDeliverables_v2({ commit }, id) {
    return this.$repository.mentor.v2.userProfile
      .postGetDeliverables({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_DELIVERABLE', {
          type: 'deliverables',
          data: data.data,
        })
        commit('SET_STATE_DELIVERABLE', {
          type: 'talent_id',
          data: id,
        })
        return data
      })
  },

  getAll({ commit }, params) {
    return this.$repository.mentor.v2.deliverables
      .getAll(params)
      .then((res) => {
        const {
          data: {
            data: { table, statuses },
          },
        } = res
        const isDeliveries = params.type === 'deliveries'
        commit('SET_ALL_DELIVERABLES', {
          type: isDeliveries ? 'tableDeliveries' : 'tableRevisions',
          data: table.data,
        })
        commit('SET_ALL_DELIVERABLES', {
          type: 'statuses',
          data: statuses,
        })
        commit('SET_ALL_DELIVERABLES', {
          type: isDeliveries ? 'paginationDeliveries' : 'paginationRevisions',
          data: {
            pageSize: table.per_page,
            current: table.current_page,
            total: table.total,
            showLessItems: true,
            showQuickJumper: false,
            hideOnSinglePage: false,
            showTotal: (total) => `Total ${total} resultados`,
          },
        })
        return res.data
      })
  },

  async fetchPendings({ dispatch }) {
    await dispatch('fetchPendingRevisions')
    await dispatch('fetchPendingDeliverables')
  },

  async fetchPendingRevisions({ commit }, params) {
    const { success, result } =
      await this.$repository.v3.mentor.deliverables.getPendingRevisions(params)
    if (!success) return
    commit('SET_ALL_DELIVERABLES', {
      type: 'tableRevisions',
      data: result.data,
    })
    commit('SET_ALL_DELIVERABLES', {
      type: 'paginationRevisions',
      data: {
        pageSize: result.per_page,
        current: result.current_page,
        total: result.total,
        showLessItems: true,
        showQuickJumper: false,
        hideOnSinglePage: false,
        showTotal: (total) => `Total ${total} resultados`,
      },
    })
    commit('SET_COUNT_PENDING_REVISIONS', {
      data: result.total,
    })
  },

  async fetchPendingDeliverables({ commit }, params) {
    const { success, result } =
      await this.$repository.v3.mentor.deliverables.getPendingDeliverables(
        params
      )
    if (!success) return
    commit('SET_ALL_DELIVERABLES', {
      type: 'tableDeliveries',
      data: result.data,
    })
    commit('SET_ALL_DELIVERABLES', {
      type: 'paginationDeliveries',
      data: {
        pageSize: result.per_page,
        current: result.current_page,
        total: result.total,
        showLessItems: true,
        showQuickJumper: false,
        hideOnSinglePage: false,
        showTotal: (total) => `Total ${total} resultados`,
      },
    })
    commit('SET_COUNT_PENDING_DELIVERABLES', {
      data: result.total,
    })
  },

  saveDeliverable({ commit }, payload) {
    const { deliverable, state, index, feedbackVideo } = payload

    return this.$repository.mentor.userProfile
      .postSaveDeliverable({
        user_deliverable_id: deliverable,
        user_deliverable_status_id: state,
        feedback_video: feedbackVideo,
      })
      .then(({ data }) => {
        commit('SET_DELIVERABLE', {
          status: data.data.user_deliverable_status_id,
          index,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  sendFeedback({ commit }, payload) {
    return this.$repository.mentor.v2.deliverables
      .postSendFeedback(payload)
      .then(({ data }) => {
        commit('SET_DELIVERABLE', {
          status: data.data.user_deliverable_status_id,
          index: payload.index,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  getFilterDeliverables({ commit }) {
    return this.$repository.v2.support
      .getFilterDeliverables()
      .then(({ data }) => {
        commit('SET_STATE_DELIVERABLE', {
          type: 'filter_deliverables',
          data: data.data,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  getDeliverablesByMentor({ commit }) {
    return this.$repository.v3.mentor
      .getDeliverablesByMentor()
      .then(({ data }) => {
        const { list, total } = data.result
        commit('SET_DELIVERABLES_BY_MENTOR', {
          data: list,
        })
        commit('SET_COUNT_TOTAL_DELIVERABLES', {
          data: total,
        })
        return data.result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}

export const getters = {
  getPendingsCount(state) {
    return state.count_pending_deliverables + state.count_pending_revisions
  },
}
