
export default {
  name: 'TotalPlacementCompletedCount',
  data() {
    return {
      count: 0,
    }
  },
  async mounted() {
    const { result, success } =
      await this.$repository.mentor.v2.support.getTotalPlacementCompleted()

    if (success) {
      this.count = result
    }
  },
}
