import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/customer-success/user'

export default () => ({
  getPersonalObjectives(userId) {
    return useRequestOffice('get', `${resource}/${userId}/personal-objetives`)
  },
  getDeliverableNotifications(userId) {
    return useRequestOffice(
      'get',
      `${resource}/${userId}/settings/deliverable-notifications`
    )
  },
})
