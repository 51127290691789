import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/admin/objective-types'

export default () => ({
  getTypeObjective(params) {
    return useRequestOffice('get', `${resource}`, { params })
  },
  getTargetObjective(params) {
    return useRequestOffice('get', `${resource}/target-values`, { params })
  },
})
