const templateError = {
  data: {
    statusCode: 500,
    success: false,
    message: 'Error desconocido',
  },
}

export default function ({ $axios, redirect }) {
  $axios.onError(async (error) => {
    if (!error.response) return Promise.reject(templateError)
    const responseData = error.response.data

    // preparación de respuesta de error
    const errorResponse = {
      data: { ...responseData, statusCode: error.response.status },
    }

    // Expulsar si es un error de autenticación
    if (
      error.response.status === 401 &&
      error.response.config.url === '/mentor/auth/me'
    ) {
      await redirect('/logout/')
    }

    // Enviar error a slack y sentry
    const ignoreErrors = [404, 401, 422]
    if (!ignoreErrors.includes(error.response.status)) {
      if (process.env.NODE_ENV === 'production') {
        // TODO: Implementar bot de warning de slack
      }
    }

    return Promise.reject(errorResponse)
  })
}
