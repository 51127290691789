import {
  CONFIG_KANBAN,
  PREFILTER_ID,
  REJECTED_ID,
  SUGGESTIONS_ID,
} from '~/assets/data/everest/offerts/approved/kanbanStatus'
import { responseStore } from '~/utils/store'

const columnKanban = {
  data: [],
  loading: true,
  pagination: {
    current_page: 0,
    last_page: 0,
    total: 0,
  },
}

const initialState = {
  kanban: CONFIG_KANBAN.map((item) => {
    return {
      ...item,
      params: {
        type: item.id,
        page: 1,
      },
      ...columnKanban,
    }
  }),
  historyChanges: [],
  showModalDetails: false,
  detailsModal: {
    columnId: null,
    columnIndex: null,
    elementIndex: null,
    element: null,
  },
  settings: {
    cards: {
      avatar: false,
      profile: true,
      city: true,
      english: true,
      stack: true,
      skills: false,
      footerActions: true,
    },
    columns: {
      width: 278,
    },
  },

  userDetails: null,
  interviews: [],
  references: [],
  affinity: 0,
  loadingDetail: false,
  interviewsCompany: [],

  countReject: 0,
}

export const state = () => ({ ...initialState })

export const getters = {
  getLastMove: (state) => {
    if (state.historyChanges.length === 0) return null
    return state.historyChanges[0]
  },
}

export const mutations = {
  SET_STATE_KANBAN(state, { type, value }) {
    state[type] = value
  },
  UPDATE_KEY_COLUMN(state, { index, key, value }) {
    state.kanban[index][key] = value
  },
  SET_ELEMENT_COLUMN_INDEX(state, { index, value }) {
    state.kanban[index].data = value
  },
  ADD_ELEMENTS_COLUMN(state, { columnId, data, pagination }) {
    const indexAdd = state.kanban.findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      state.kanban[indexAdd].data.push(...data)
      state.kanban[indexAdd].pagination = pagination
    }
  },
  ADD_ELEMENT_COLUMN(state, { columnId, element }) {
    const indexAdd = state.kanban.findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      const indexElement = state.kanban[indexAdd].data.findIndex(
        (item) => item.id < element.id
      )

      if (indexElement !== -1) {
        state.kanban[indexAdd].data.splice(indexElement, 0, element)
      } else {
        state.kanban[indexAdd].data.push(element)
      }

      state.kanban[indexAdd].pagination.total += 1
    }
  },
  REMOVE_ELEMENT_COLUMN(state, { columnId, element }) {
    const indexAdd = state.kanban.findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      const indexElement = state.kanban[indexAdd].data.findIndex(
        (item) => item.id === element.id
      )

      if (indexElement !== -1) {
        state.kanban[indexAdd].data.splice(indexElement, 1)
        state.kanban[indexAdd].pagination.total -= 1
      }
    }
  },
  ADD_HISTORY_KANBAN(state, payload) {
    const indexAdd = state.historyChanges.findIndex(
      (item) => item.finalized === false
    )

    if (indexAdd !== -1) {
      state.historyChanges[indexAdd] = {
        ...state.historyChanges[indexAdd],
        ...payload,
      }
      return
    }

    state.historyChanges.unshift(payload)
  },
  REVERSE_KANBAN_CHANGE(state) {
    if (state.historyChanges.length === 0) return
    const { added, removed, element } = state.historyChanges[0]
    state.kanban[added.columnIndex].data.splice(added.newIndex, 1)
    state.kanban[removed.columnIndex].data.splice(removed.oldIndex, 0, element)
    state.historyChanges.splice(0, 1)
  },
  UPDATE_KEY_ELEMENT(state, { columnIndex, elementIndex, key, value }) {
    state.kanban[columnIndex].data[elementIndex][key] = value
  },
  UPDATE_SETTING_CARD(state, { key, value }) {
    state.settings.cards[key] = value
  },
  UPDATE_SETTING_COLUMN(state, { key, value }) {
    state.settings.columns[key] = value
  },
}

export const actions = {
  async fetchOfferKanban(
    { state, commit, rootState },
    { offerId, force = false }
  ) {
    if (
      rootState.everest.offer.offer !== null &&
      rootState.everest.offer.offer.id === offerId &&
      force === false
    ) {
      return responseStore(state.kanban)
    }

    const response =
      await this.$repository.v3.partnership.offers.getOffersKanban(offerId)

    if (response.success) {
      let formatKanban = response.result.map((item) => {
        const { id, data, name } = item
        const findColumn = state.kanban.find(
          (itemKanban) => itemKanban.id === item.id
        )

        const newData = {
          data: [],
          pagination: {
            current_page: 0,
            last_page: 0,
            total: 0,
          },
        }

        if (data) {
          newData.loading = false
          newData.data = data.data
          newData.pagination = data.paginate
        }

        return { ...findColumn, ...newData, id, name }
      })

      formatKanban = formatKanban.filter(
        (item) => ![SUGGESTIONS_ID, PREFILTER_ID, REJECTED_ID].includes(item.id)
      )

      commit('SET_STATE_KANBAN', { type: 'kanban', value: formatKanban })
    }

    return response
  },
  async fetchColumnKanban({ state, commit }, payload) {
    const { offerId, params } = payload

    const response =
      await this.$repository.v3.partnership.offers.getOffersColumnKanban(
        offerId,
        params
      )

    if (response.success) {
      commit('ADD_ELEMENTS_COLUMN', {
        columnId: params.type,
        data: response.result.data,
        pagination: response.result.paginate,
      })
    }

    return response
  },
  async fetchDataUser({ commit }, { id, params }) {
    const response =
      await this.$repository.v3.partnership.matchUser.getUserMach(id, params)

    if (response.success) {
      commit('SET_STATE_KANBAN', {
        type: 'userDetails',
        value: response.result,
      })
    }
    return response
  },
  async fetchInterviews({ commit }, { idOffer, idUser }) {
    const response =
      await this.$repository.v3.partnership.offers.getUserInterviews(
        idOffer,
        idUser
      )

    if (response.success) {
      commit('SET_STATE_KANBAN', {
        type: 'interviews',
        value: response.result,
      })
    }
    return response
  },
  async fetchReferences({ commit }, { idOffer, idUser }) {
    const response = await this.$repository.v3.partnership.offers.getReferences(
      idOffer,
      idUser
    )
    if (response.success) {
      commit('SET_STATE_KANBAN', {
        type: 'references',
        value: response.result,
      })
    }
    return response
  },
  async fetchAffinity({ commit }, { idOffer, idUser }) {
    const response = await this.$repository.v3.partnership.offers.getAffinity(
      idOffer,
      idUser
    )
    if (response.success) {
      commit('SET_STATE_KANBAN', {
        type: 'affinity',
        value: response.result.affinity,
      })
    }
    return response
  },
  async fetchInterviewsCompany({ commit }, idUser) {
    const response =
      await this.$repository.v3.partnership.matchUser.getUserInterviewsCompany(
        idUser
      )

    if (response.success) {
      commit('SET_STATE_KANBAN', {
        type: 'interviewsCompany',
        value: response.result,
      })
    }
    return response
  },
}
