const resource = '/api/v3/mentor/talent-notes'

export default ($axios) => ({
  getNotes(params) {
    return $axios.post(`${resource}/`, params)
  },
  postCreateNote(noteBody) {
    return $axios.post(`${resource}/`, noteBody)
  },
  putNote(talentNoteId, note) {
    return $axios.put(`${resource}/${talentNoteId}`, note)
  },
  deleteNote(talentNoteId) {
    return $axios.delete(`${resource}/${talentNoteId}`)
  },
})
