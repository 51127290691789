const initialState = {
  week: [],
  pagination: {
    total: 1,
    pageSize: 10,
    current: 1,
  },
}

export const state = () => ({
  ...initialState,
})

export const mutations = {
  SET_STATE_WEEK(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getListWeek({ commit }, params) {
    const { result, success } =
      await this.$repository.v3.admin.missions.getListWeek(params)
    if (success) {
      commit('SET_STATE_WEEK', {
        type: 'week',
        data: result.data,
      })
      commit('SET_STATE_WEEK', {
        type: 'pagination',
        data: {
          pageSize: result.per_page,
          current: result.current_page,
          total: result.total,
        },
      })
    } else {
      commit('SET_STATE_WEEK', {
        type: 'week',
        data: [],
      })
    }
    return result
  },
  async deleteWeek({ dispatch }, id) {
    const { result, success } =
      await this.$repository.v3.admin.missions.deleteWeek(id)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
  async createWeek({ dispatch }, newWeek) {
    const { result, success } =
      await this.$repository.v3.admin.missions.postWeek(newWeek)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
  async updateWeek({ dispatch }, { id, newWeek }) {
    const { result, success } =
      await this.$repository.v3.admin.missions.putWeek(id, newWeek)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
}
