const resource = '/api/v3/mentor/group-sessions'

export default ($axios) => ({
  postChangeStatusSession(groupId, status) {
    return $axios.post(`${resource}/${groupId}/update-status`, {
      status,
    })
  },
  /* Attendance */
  getGroupSessionById(sessionId) {
    return $axios.get(`${resource}/${sessionId}`)
  },
  /* General Notes */
  getNotes(groupSessionId) {
    return $axios.get(`${resource}/${groupSessionId}/notes`)
  },
  postNote(groupSessionId, content) {
    return $axios.post(`${resource}/${groupSessionId}/notes`, {
      content,
    })
  },
  /* Postulation */
  getListPostulation(groupSessionId, status) {
    return $axios.get(`${resource}/${groupSessionId}/users-applicants${status}`)
  },
  /* Deliverables */
  getListDeliverables(groupSessionId, status, selectCheckbox) {
    return $axios.get(
      `${resource}/${groupSessionId}/deliverables${status}${selectCheckbox}`
    )
  },
})
