const initialState = {
  businesses: null,
  pagination: {},
  business: {},
  businessServices: {},
  availableBusinessServices: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_BUSINESSES(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchBusinesses({ commit }, params) {
    const { result, success } =
      await this.$repository.v3.partnership.businesses.getBusinesses(params)
    if (success) {
      commit('SET_STATE_BUSINESSES', {
        type: 'businesses',
        value: result,
      })

      commit('SET_STATE_BUSINESSES', {
        type: 'pagination',
        value: {
          pageSize: result.paginate.per_page,
          current: result.paginate.current_page,
          total: result.paginate.total,
        },
      })
    }
    return { result, success }
  },
  async showBusinesses({ commit }, { id }) {
    const response =
      await this.$repository.v3.partnership.businesses.showBusinesses(id)

    if (response.success) {
      commit('SET_STATE_BUSINESSES', {
        type: 'business',
        value: response.result,
      })
    }

    return response
  },
  async updateBusinesses({ dispatch }, { id, body }) {
    const response =
      await this.$repository.v3.partnership.businesses.putBusinesses(id, body)

    if (response.success) {
      await dispatch('fetchBusinesses', {})
    }
    return response
  },
  async createBusinesses({ dispatch }, { body }) {
    const response =
      await this.$repository.v3.partnership.businesses.postBusinesses(body)
    if (response.success) {
      await dispatch('fetchBusinesses', {})
    }
    return response
  },
  async createBusinessForProspect({ dispatch }, { idLead, body }) {
    const response =
      await this.$repository.v3.partnership.businesses.postBusinessForProspect(
        idLead,
        body
      )
    if (response.success) {
      await dispatch('fetchBusinesses', {})
    }
    return response
  },
  async updateActiveBusinessesStatus({ dispatch }, { id, params = {} }) {
    const response =
      await this.$repository.v3.partnership.businesses.patchChangeStatusActive(
        id
      )
    if (response.success) {
      await dispatch('fetchBusinesses', params)
    }
    return response
  },
  async updateInactiveBusinessesStatus({ dispatch }, { id, params = {} }) {
    const response =
      await this.$repository.v3.partnership.businesses.patchChangeStatusInactive(
        id
      )
    if (response.success) {
      await dispatch('fetchBusinesses', params)
    }
    return response
  },
  async createOrder({ dispatch }, { businessId, body }) {
    const response = await this.$repository.v4.sales.businesses.postOrder(
      businessId,
      body
    )
    if (response.success) {
      await dispatch('fetchBusinessServices', businessId)
    }
    return response
  },
  async fetchBusinessServices({ commit }, id) {
    const { result, success } =
      await this.$repository.v4.sales.businesses.getBusinessesServices(id)

    if (success) {
      commit('SET_STATE_BUSINESSES', {
        type: 'businessServices',
        value: result,
      })
    }

    return { result, success }
  },
  async updateBusinessServiceField(
    { dispatch },
    { businessId, businessServiceId, body }
  ) {
    const response =
      await this.$repository.v4.sales.businesses.putBusinessServiceField(
        businessId,
        businessServiceId,
        body
      )

    if (response.success) {
      await dispatch('fetchBusinessServices', businessId)
    }

    return response
  },
  async updateBusinessService(
    { dispatch },
    { businessId, businessServiceId, body }
  ) {
    const response =
      await this.$repository.v4.sales.businesses.putBusinessService(
        businessId,
        businessServiceId,
        body
      )

    if (response.success) {
      await dispatch('fetchBusinessServices', businessId)
    }
    return response
  },
  async deleteBusinessService({ dispatch }, { businessId, businessServiceId }) {
    const response =
      await this.$repository.v4.sales.businesses.deleteBusinessService(
        businessId,
        businessServiceId
      )

    if (response.success) {
      await dispatch('fetchBusinessServices', businessId)
    }
    return response
  },
  clearAvailableBusinessUsers({ commit }) {
    commit('SET_STATE_BUSINESSES', {
      type: 'availableBusinessServices',
      value: [],
    })
  },
  async getAvailableBusinessServices({ commit }, businessId) {
    const { result, success } =
      await this.$repository.v3.partnership.businesses.getAvailableBusinessServices(
        businessId
      )

    if (success) {
      commit('SET_STATE_BUSINESSES', {
        type: 'availableBusinessServices',
        value: result,
      })
    }
  },
}
