import { notification } from 'ant-design-vue'

export const state = () => ({
  notes: [],
})

export const mutations = {
  SET_STATE_NOTES(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  getNotes({ commit }, id) {
    return this.$repository.v3.admission.applicant
      .getNotes(id)
      .then(({ data }) => {
        commit('SET_STATE_NOTES', {
          type: 'notes',
          data: data.result,
        })
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createNote({ dispatch }, content) {
    return this.$repository.v3.mentor.notes
      .postCreateNote(content)
      .then(async ({ data }) => {
        const { result } = data
        await dispatch('getNotes', result.applicant_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateNote({ dispatch }, data) {
    return this.$repository.v3.mentor.notes
      .putNote(data.note_id, { note: data.note })
      .then(async ({ data }) => {
        const { result } = data
        await dispatch('getNotes', result.applicant_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ dispatch }, content) {
    const { note_id, idApplicant } = content
    return this.$repository.v3.mentor.notes
      .deleteNote(note_id)
      .then(async ({ data }) => {
        await dispatch('getNotes', idApplicant)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
