import { GrowthBook } from '@growthbook/growthbook'

export default ({ store, $config }, inject) => {
  const growthBook = new GrowthBook({
    apiHost: $config.feature_flags.apiHost,
    clientKey: $config.feature_flags.clientKey,
    enableDevMode: $config.feature_flags.enableDevMode,
  })

  inject('feature', growthBook)

  // Feature Boolean
  inject('hasFeature', (feature) => growthBook.isOn(feature.toLowerCase()))

  // Feature json, number, string
  inject('getFeature', (key, defaultValue) =>
    growthBook.getFeatureValue(key.toLowerCase(), defaultValue)
  )
}
