import { useRequestOffice } from '~/repositories'

const rootPath = 'api/v3/partnership/match-users'

export default () => ({
  getUsers(params) {
    return useRequestOffice('get', `/${rootPath}`, { params })
  },
  postUser(params) {
    return useRequestOffice('post', `/${rootPath}`, params)
  },
  getUserMach(userId, params) {
    return useRequestOffice('get', `/${rootPath}/${userId}`, { params })
  },
  deleteMatchUser(userId) {
    return useRequestOffice('delete', `/${rootPath}/${userId}`)
  },
  patchRestoreMatchUser(userId) {
    return useRequestOffice('patch', `/${rootPath}/${userId}/restore`)
  },
  convertToApplicant(userId) {
    return useRequestOffice(
      'post',
      `/${rootPath}/${userId}/convert-to-applicant`
    )
  },
  getDataPlacement(userId) {
    return useRequestOffice('get', `${rootPath}/${userId}/placement-info`)
  },

  /* ENTREVISTA */
  postInterview(idUser, params) {
    return useRequestOffice('post', `${rootPath}/${idUser}/interviews`, params)
  },
  getInterviewById(idUser) {
    return useRequestOffice('get', `${rootPath}/${idUser}/interviews`)
  },
  // uploadFileInterview(idUser, params) {
  //   return $axios
  //     .post(`${rootPath}/${idUser}/interview/prefilter/upload-file`, params)
  //     .then(({ data }) => data)
  //     .catch(({ data }) => data)
  // },
  getFilesInterviewByOffer(idUser, idOffer) {
    return useRequestOffice(
      'get',
      `${rootPath}/${idUser}/interview/prefilter/get-files/work-offers/${idOffer}`
    )
  },
  deleteFileInterview(idFile) {
    return useRequestOffice(
      'delete',
      `${rootPath}/interview/prefilter/remove-file/${idFile}`
    )
  },
  updaSalaryExpectation(idUser, params) {
    return useRequestOffice(
      'put',
      `${rootPath}/${idUser}/interview/prefilter/update-salary-expectation`,
      params
    )
  },
  validatePhoneNumber(idUser, params) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idUser}/validate-phone-number`,
      params
    )
  },
  getUserInterviewsCompany(idUser) {
    return useRequestOffice('get', `${rootPath}/${idUser}/business-interviews`)
  },
})
