const rootPath = '/mentor/user_profile'
const rootPath2 = '/mentor/user-profile'

export default ($axios) => ({
  postUpdateStatus(payload) {
    return $axios.post(`${rootPath}/change_user_status`, payload)
  },

  postGetMentoring(payload) {
    return $axios.post(`${rootPath}/calendly_mentoring_sessions`, payload)
  },
  postCreateMentoring(payload) {
    return $axios.post(`${rootPath}/add_mentoring_sessions`, payload)
  },
  postChangeStatusMentoringSession(payload) {
    return $axios.post(`${rootPath}/change_state_mentoring_session`, payload)
  },

  postGetDeliverables(payload) {
    return $axios.post(`${rootPath}/deliverables`, payload)
  },
  postSaveDeliverable(payload) {
    return $axios.post(`${rootPath}/change_deliverable_status`, payload)
  },

  profileBasic(payload) {
    return $axios.post(`${rootPath}/show_basic`, payload)
  },

  profileComplete(userId) {
    return $axios
      .post(`${rootPath}/show_complete`, {
        user_id: userId,
      })
      .then(({ data }) => data)
      .catch(({ data }) => data)
  },

  updateOnboarding(payload) {
    return $axios.post(`${rootPath}/onboarding_session`, payload)
  },

  postAllRisks() {
    return $axios.post(`${rootPath}/get_risk_level_qualifications`)
  },

  postUpdateRisk(payload) {
    return $axios.post(`${rootPath}/change_risk_level_qualification`, payload)
  },
  postGetProgress(payload) {
    return $axios.post(`${rootPath}/progress`, payload)
  },

  postGetStreak(payload) {
    return $axios.post(`${rootPath}/last_6_weeks_applications`, payload)
  },

  postGetStages(payload) {
    return $axios.post(`${rootPath}/accordion`, payload)
  },
  getAllCountries() {
    return $axios.get(`${rootPath}/placement/countries`)
  },

  getAllTypesMoney() {
    return $axios.get(`${rootPath}/placement/types-money`)
  },

  postUpdateActivatedAt(payload) {
    return $axios.post(`${rootPath2}/activated-at`, payload)
  },
})
