import { notification } from 'ant-design-vue'

const initialState = {
  notes: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_NOTES(state, { type, value }) {
    state[type] = value
  },
  ADD_NOTE(state, payload) {
    state.notes.unshift(payload)
  },
  UPDATE_NOTE(state, payload) {
    const indexUpdate = state.notes.findIndex((item) => item.id === payload.id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1, payload)
    }
  },
  DELETE_NOTE(state, id) {
    const indexUpdate = state.notes.findIndex((item) => item.id === id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1)
    }
  },
}

export const actions = {
  getNotes({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postGetNotes(payload)
      .then(({ data }) => {
        commit('SET_STATE_NOTES', {
          type: 'notes',
          value: data.data,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createNote({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postCreateNote(payload)
      .then(({ data }) => {
        commit('ADD_NOTE', data.data)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateNote({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postUpdateNote(payload)
      .then(({ data }) => {
        commit('UPDATE_NOTE', data.data)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postDeleteNote(payload)
      .then(({ data }) => {
        commit('DELETE_NOTE', payload.note_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
