import { useRequestOffice } from '~/repositories'

export default () => ({
  getUsers(businessId) {
    return useRequestOffice(
      'get',
      `/api/v4/partnership/businesses/${businessId}/users/`
    )
  },
  getPromotionCodes(businessId) {
    return useRequestOffice(
      'get',
      `/api/v4/partnership/businesses/${businessId}/promotion-codes/`
    )
  },
  createPromotionCode(businessId, data) {
    return useRequestOffice(
      'post',
      `/api/v4/partnership/businesses/${businessId}/promotion-codes/`,
      data
    )
  },
})
