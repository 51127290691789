const initialState = {
  activities: [],
  activityTypes: [],
  targetTypes: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_ACTIVITY(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async getActivities({ commit }, idWeek) {
    const response = await this.$repository.v3.admin.objectives.getActivities(
      idWeek
    )

    if (response.success) {
      const orderData = response.result.data.sort((a, b) => a.order - b.order)
      commit('SET_STATE_ACTIVITY', {
        type: 'activities',
        value: orderData,
      })
    }

    return response
  },
  async createActivity({ dispatch }, newObjective) {
    const response = await this.$repository.v3.admin.objectives.postObjective(
      newObjective
    )

    if (response.success) {
      await dispatch('getActivities', newObjective.mission_id)
    }

    return response
  },
  async updateActivity({ dispatch }, { id, newObjective }) {
    const response = await this.$repository.v3.admin.objectives.putObjective(
      id,
      newObjective
    )

    if (response.success) {
      await dispatch('getActivities', newObjective.mission_id)
    }

    return response
  },
  async deleteActivity({ dispatch }, { id, weekId }) {
    const response = await this.$repository.v3.admin.objectives.deleteObjective(
      id
    )

    if (response.success) {
      await dispatch('getActivities', weekId)
    }

    return response
  },

  // Data extra
  async getActivityTypes({ commit }, params) {
    const response =
      await this.$repository.v3.admin.objectiveTypes.getTypeObjective(params)

    if (response.success) {
      commit('SET_STATE_ACTIVITY', {
        type: 'activityTypes',
        value: response.result,
      })
    }

    return response
  },
  async getTargets({ commit }, params) {
    const response =
      await this.$repository.v3.admin.objectiveTypes.getTargetObjective(params)

    if (response.success) {
      commit('SET_STATE_ACTIVITY', {
        type: 'targetTypes',
        value: response.result,
      })
    }

    return response
  },
}
