const resource = '/api/v3/mentor/group-session-notes'

export default ($axios) => ({
  getNoteById(noteId) {
    return $axios.get(`${resource}/${noteId}`)
  },
  putGroupSessionNote(noteId, content) {
    return $axios.put(`${resource}/${noteId}`, {
      content,
    })
  },
  deleteGroupSessionNote(noteId) {
    return $axios.delete(`${resource}/${noteId}`)
  },
})
