import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v4/match/roles'

export default () => ({
  getRoles(params) {
    const queryString = new URLSearchParams(params).toString()
    return useRequestOffice('get', `${rootPath}?${queryString}`)
  },
  getRole(roleId) {
    return useRequestOffice('get', `${rootPath}/${roleId}`)
  },
  storeRole(params) {
    return useRequestOffice('post', `${rootPath}`, params)
  },
  updateRole(roleId, params) {
    return useRequestOffice('put', `${rootPath}/${roleId}`, params)
  },
  import(params) {
    return useRequestOffice('post', `${rootPath}/import`, params)
  },
  async downloadTemplate() {
    try {
      const response = await useRequestOffice(
        'get',
        `${rootPath}/skills/template/download`
      )

      if (response.result && response.result.file) {
        const base64String = response.result.file
        const fileName = response.result.file_name

        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    } catch (error) {
      console.error('Error al descargar la plantilla:', error)
    }
  },
})
