
export default {
  name: 'ErrorView',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500,
        message: 'Error de servidor',
      }),
    },
  },
}
