import { notification } from 'ant-design-vue'

export const state = () => ({
  talent_id: null,
  mentoring: 4,
})

export const mutations = {
  SET_STATE_MENTORING(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  getMentoring({ commit }, id) {
    return this.$repository.mentor.userProfile
      .postGetMentoring({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_MENTORING', {
          type: 'talent_id',
          data: id,
        })
        commit('SET_STATE_MENTORING', {
          type: 'mentoring',
          data: data.data,
        })

        return data
      })
  },
  addMentoring({ commit }, payload) {
    return this.$repository.mentor.userProfile
      .postCreateMentoring(payload)
      .then(({ data }) => {
        commit(
          'mentor/profile/SET_PROFILE_MENTORING',
          data.data.mentoring_sessions,
          {
            root: true,
          }
        )

        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  changeStatusMentoringSession(_, payload) {
    return this.$repository.mentor.userProfile
      .postChangeStatusMentoringSession(payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
