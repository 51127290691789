const resource = '/api/v3/mentor/user-deliverables'

export default ($axios) => ({
  /* Deliverables Panels */
  getDeliverableById(userDeliverableId) {
    return $axios.get(`${resource}/${userDeliverableId}`)
  },
  postUpdateUrlDeliverable(userDeliverableId, deliverableUrl) {
    return $axios.post(`${resource}/${userDeliverableId}/update-url`, {
      deliverable_url: deliverableUrl,
    })
  },
  postUpdateUrlFeedback(userDeliverableId, content) {
    return $axios.post(
      `${resource}/${userDeliverableId}/update-feedback`,
      content
    )
  },
  postUpdateCommentFeedback(userDeliverableId, content) {
    return $axios.post(
      `${resource}/${userDeliverableId}/update-comment`,
      content
    )
  },
  postUpdateUrlCorrected(userDeliverableId, content) {
    return $axios.post(
      `${resource}/${userDeliverableId}/update-url-corrected`,
      content
    )
  },
  postApproveDeliverable(userDeliverableId) {
    return $axios.post(`${resource}/${userDeliverableId}/approve`)
  },
  postRevertDeliverable(userDeliverableId) {
    return $axios.post(`${resource}/${userDeliverableId}/revert`)
  },
})
