const initialState = {
  modalForContractApproval: false,
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_MODAL(state, { type, value }) {
    state[type] = value
  },
}
