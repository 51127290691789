import { notification } from 'ant-design-vue'

export const state = () => ({
  notes: [],
})

export const getters = {
  getNotesMentoring: (state) => (id) => {
    if (!id) return []

    const filter = state.notes.filter(
      (item) => item.calendly_mentoring_session_id === id
    )

    return filter.slice().reverse()
  },
}

export const mutations = {
  SET_STATE_NOTES(state, { type, data }) {
    state[type] = data
  },
  ADD_NOTE(state, payload) {
    state.notes.push(payload)
  },
  UPDATE_NOTE(state, payload) {
    const indexUpdate = state.notes.findIndex((item) => item.id === payload.id)
    state.notes.splice(indexUpdate, 1, payload)
  },
  DELETE_NOTE(state, id) {
    const indexDelete = state.notes.findIndex((item) => item.id === id)
    state.notes.splice(indexDelete, 1)
  },
}

export const actions = {
  async fetchNotes({ commit }, payload) {
    const response = await this.$repository.mentor.notes.getNotes(payload)

    if (response.success) {
      commit('SET_STATE_NOTES', {
        type: 'notes',
        data: response.data[0],
      })
    }

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }
    return response
  },
  async createNote({ commit }, payload) {
    const response = await this.$repository.mentor.notes.postCreateNote(payload)

    if (response.success) {
      commit('ADD_NOTE', response.data[0])
    }

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    return response
  },
  async updateNote({ commit }, payload) {
    const response = await this.$repository.mentor.notes.postUpdateNote(payload)

    if (response.success) {
      commit('UPDATE_NOTE', response.data[0])
    }

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    return response
  },
  async deleteNote({ commit }, payload) {
    const response = await this.$repository.mentor.notes.postDeleteNote(payload)

    if (response.success) {
      commit('DELETE_NOTE', payload.mentor_user_note_id)
    }

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    return response
  },
}
