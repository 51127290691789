import { notification } from 'ant-design-vue'

export const actions = {
  async rejectMatchUserRegister({ state, commit }, matchUserId) {
    const response =
      await this.$repository.v3.admission.matchUsers.rejectMatchUserRegister(
        matchUserId
      )
    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }
    return response
  },
  async acceptMatchUserRegister({ state, commit }, matchUserId) {
    const response =
      await this.$repository.v3.admission.matchUsers.acceptMatchUserRegister(
        matchUserId
      )
    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }
    return response
  },
}
