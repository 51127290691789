import { notification } from 'ant-design-vue'

const initialState = {
  talent: null,
  contractUrl: null,
  paymentSchedule: {},
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_USER(state, { type, value }) {
    state[type] = value
  },
  UPDATE_STATE_USER(state, { type, value }) {
    if (state.talent) {
      state.talent[type] = value
    }
  },
  SET_STATE_SUCCESS_FEE(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  getApplicant({ commit }, params) {
    return this.$repository.mentor.applicants
      .postGetApplicant(params)
      .then(({ data }) => {
        commit('SET_STATE_USER', {
          type: 'talent',
          value: data.data,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  saveContract({ commit }, payload) {
    return this.$repository.mentor.v2.userProfile
      .postSaveContract(payload.id, payload.val)
      .then(({ data }) => {
        commit('UPDATE_STATE_USER', {
          type: 'contract_status',
          value: payload.val,
        })
        commit(
          'collection/filter/SET_CONTRACT_TALENTS',
          { userID: payload.id, contract: payload.val },
          { root: true }
        )
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  payManually({ state, dispatch }, payload) {
    return this.$repository.mentor.applicants
      .postPayManually(payload)
      .then(async ({ data }) => {
        await dispatch('getApplicant', { applicant_id: state.talent.id })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  confirmPayment({ state, dispatch }, payload) {
    return this.$repository.mentor.applicants
      .postConfirmPayment(payload)
      .then(async ({ data }) => {
        await dispatch('getApplicant', { applicant_id: state.talent.id })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updatePayment({ state, dispatch }, payload) {
    return this.$repository.mentor.applicants
      .postUpdatePayment(payload)
      .then(async ({ data }) => {
        await dispatch('getApplicant', { applicant_id: state.talent.id })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deletePayment({ state, dispatch }, payload) {
    return this.$repository.mentor.applicants
      .postDeletePayment(payload)
      .then(async (data) => {
        await dispatch('getApplicant', { applicant_id: state.talent.id })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getTalentSignedContractURL({ commit }, id) {
    return this.$repository.v3.collection.contract
      .getTalentSignedContractURL(id)
      .then(({ data }) => {
        const { result = null } = data
        commit('SET_STATE_USER', {
          type: 'contractUrl',
          value: result,
        })
        return result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getEmulatePaymentSchedule({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postGetEmulatePaymentSchedule(payload)
      .then(({ data }) => {
        const { data: response } = data
        commit('SET_STATE_SUCCESS_FEE', {
          type: 'paymentSchedule',
          value: response,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: 'Error al procesar el cronograma',
        })
        return data
      })
  },
  postSavedPaymentSchedule({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postSavedPaymentSchedule(payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: 'Error al guardar el cronograma',
        })
        return data
      })
  },
  postSendEmailPaymentSchedule({ commit }, payload) {
    return this.$repository.mentor.applicants
      .postSendEmailPaymentSchedule(payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: 'Error al enviar el cronograma por correo.',
        })
        return data
      })
  },
}
