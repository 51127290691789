import { supportGlobal } from '~/config/support'

export default async ({ app, store, $feature, route }) => {
  if (!['/', '/logout/'].includes(route.path)) {
    if (!app.context.ready) {
      app.context.ready = true
      await store.dispatch('support/fetchSupport', supportGlobal)
    }
  }

  if (!$feature.ready) {
    await $feature.loadFeatures({ autoRefresh: true })
  }
}
