import { useRequestOffice } from '~/repositories'
const admissionFollowUp = '/api/v3/admission/applicants/followup'

export default ($axios) => ({
  getFollowUpList(params) {
    return $axios.get(`${admissionFollowUp}/list`, { params })
  },

  // Notes
  getNotes(applicantId) {
    return $axios.get(`${admissionFollowUp}/notes/${applicantId}`)
  },
  postCreateNote(params) {
    return $axios.post(`${admissionFollowUp}/notes`, params)
  },
  postUpdateNote(params) {
    return $axios.post(`${admissionFollowUp}/edit_notes`, params)
  },
  postDeleteNote(params) {
    return $axios.post(`${admissionFollowUp}/delete_notes`, params)
  },

  getTotalFollowup() {
    return useRequestOffice('get', `${admissionFollowUp}/total-per-month`)
  },
})
