import Cookies from 'js-cookie'

const initialState = {
  collapsedSidebar: false,
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE(state, { type, value }) {
    state[type] = value
  },
  TOGGLE_COLLAPSED(state) {
    state.collapsedSidebar = !state.collapsedSidebar
    Cookies.set('collapsedSidebar', state.collapsedSidebar)
  },
}
