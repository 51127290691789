const resource = '/api/v3/mentor/mentors'

export default ($axios) => ({
  getMentors() {
    return $axios.get(`${resource}?withGroupSessions`)
  },
  getSessionByMentors(mentorId, status, email, day, hasPending) {
    return $axios.get(
      `${resource}/${mentorId}/group-sessions?status=${status}${email}${day}&has_pending=${hasPending}`
    )
  },
})
