
export default {
  name: 'ItemSearch',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
}
