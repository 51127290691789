import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/admission/applicants'

export default () => ({
  // ACTIVE PROCESS
  getActiveProcessesApplicant(uuid) {
    return useRequestOffice('get', `${resource}/${uuid}/active-processes`)
  },
  postStoreActiveProcessesApplicant(payload) {
    const { id, params } = payload

    return useRequestOffice(
      'post',
      `${resource}/${id}/active-processes`,
      params
    )
  },
})
