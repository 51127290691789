import Cookies from 'js-cookie'
import { datadogLogs } from '@datadog/browser-logs'
import featureFlags from '~/utils/featureFlags'
import { keySupportStorage } from '~/store/support'
import { AREAS } from '~/config/permissions'

const initialState = {
  user: null,
  loggedIn: false,
  area_id: null,
}

export const state = () => ({ ...initialState })

export const getters = {
  isDataArea: (state) => state.area_id === AREAS.DATA,
}

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_AUTH(state, { type, value }) {
    state[type] = value
    if (type === 'user') {
      state.area_id = value.area_id
    }
  },
  ADD_TOKEN(state, token) {
    this.$axios.setToken(token, 'Bearer')
    Cookies.set('auth.office.token', token, { expires: 365 })
    state.loggedIn = true
  },
  REMOVE_TOKEN(state) {
    this.$axios.setToken(false)
    Cookies.remove('auth.office.token')
    localStorage.removeItem(keySupportStorage)
    localStorage.removeItem('support')
    state.loggedIn = false

    this.$feature.setAttributes({})
    this.$feature.refreshFeatures()
  },
}

export const actions = {
  async login({ commit }, params) {
    const response = await this.$repository.auth.postLogin(params)

    if (response.success) {
      commit('ADD_TOKEN', response.access_token)
    }

    return response
  },
  async me({ commit }) {
    const response = await this.$repository.auth.getMe()

    if (response.success) {
      commit('SET_STATE_AUTH', {
        type: 'user',
        value: response.data,
      })
      this.$feature.setAttributes(featureFlags.getAttributes(response.data))
      this.$feature.refreshFeatures()
      datadogLogs.setUser({
        id: response.data.id,
        email: response.data.email,
        name: response.data.name,
      })
    }

    return response
  },
}
