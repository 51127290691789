const resource = 'api/v3/collection'
const request = 'request-remove-card-data'
const requests = 'requests-remove-card-data'

export default ($axios) => ({
  getRequests(params) {
    return $axios.get(`${resource}/${requests}`, { params })
  },
  getRequestsTotal() {
    return $axios.get(`${resource}/${requests}-total`)
  },
  postApproveRequestRemoveCard(requestId) {
    return $axios.post(`${resource}/${request}/${requestId}/approve`)
  },
  postUpdatePaymentsSchedule(params) {
    return $axios.post(`${resource}/schedule-payments`, params)
  },
})
