const resource = `${process.env.NUXT_ENV_BASE_AC_API}/balancer.php`
const calendly = `${process.env.NUXT_ENV_BASE_CALENDLY}/v1`

export default ($axios) => ({
  // vendors
  getVendors() {
    return $axios.get(`${resource}?method=index`)
  },
  // attributes vendor
  getAttributes(params) {
    return $axios.get(`${calendly}/hooks`, {
      headers: params,
    })
  },
  // delete webhook
  deleteWebhook(params) {
    return $axios.delete(`${calendly}/hooks/${params.id}`, {
      headers: {
        'x-token': params.token,
      },
    })
  },
  // create webhook
  postCreateWebhook(token, params) {
    return $axios.post(
      `${calendly}/hooks`,
      { ...params },
      {
        headers: {
          'x-token': token,
        },
      }
    )
  },
  // update vendor
  postUpdateVendor(params) {
    return $axios.post(`${resource}?method=update`, params)
  },
})
