const offerData = (offer) => {
  return {
    Cargo: offer.name,
    'Modalidad de empleo': offer.work_modality || '',
    Pais: offer.world_country || '',
    'Salario mínimo': offer.salary_min,
    'Salario máximo': offer.salary_max,
    'Nivel de inglés': offer.language_proficiency,
    Stack: offer.stacks,
    'Numero de vacantes': offer?.vacancy_qty,
    'Fecha de ingreso': offer.created_at,
  }
}

export default ({ store, $gtm }) => ({
  sendOfferApproved(offer) {
    const eventData = offerData(offer)
    $gtm.push({ event: 'Oferta aprobada', eventData })
  },
  sendOfferHealing(offer) {
    const eventData = offerData(offer)
    $gtm.push({ event: 'Oferta curada', eventData })
  },
  sendOfferActive(offer) {
    const eventData = offerData(offer)
    $gtm.push({ event: 'Oferta activa', eventData })
  },
  sendCandidates(user) {
    const userOffice = store.state.auth.user

    const eventData = {
      Cargo: user.developer_types[0]?.name,
      Pais: user?.residency_country?.name || '',
      'Salario mínimo': user.min_salary,
      'Salario máximo': user.max_salary,
      'Nivel de inglés': user?.language_proficiency?.name,
      Stack: user.stacks.map((stack) => ({
        name: stack.name,
        expertise: stack.expertise,
      })),
      Responsable: userOffice.full_name,
    }

    $gtm.push({ event: 'Candidatos enviados', eventData })
  },
  sendInteractionInfoOffer(offer, tab) {
    const eventData = {
      'id de oferta': offer.id,
      'Oferta de Trabajo': offer.name,
      Responsable: offer.mentor_id ? offer.mentor.name : '--',
      Interaccion: tab,
    }

    $gtm.push({ event: 'Interacción en información de oferta', eventData })
  },
  offerUpdateSuccess(offer, status_offer) {
    const plan_type = offer.subscription_available.label ?? ''
    const changed_offer_at = new Date().toISOString()
    const offer_id = offer.id
    const eventData = {
      plan_type,
      offer_id,
      status_offer,
      changed_offer_at,
    }

    $gtm.push({ event: 'offer_update_success', eventData })
  },
  offerApproved(offer) {
    const business_name = offer.business.name ?? ''
    const plan_type = offer.subscription_available.label ?? ''
    const saved_at = new Date().toISOString()
    const eventData = {
      plan_type,
      business_name,
      saved_at,
    }

    $gtm.push({ event: 'offer_approved', eventData })
  },
})
