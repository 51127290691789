import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/admin/calendar-session'

export default () => ({
  getFilterEvents(params) {
    return useRequestOffice('get', `${resource}/event-values`, { params })
  },
  getEvents(params) {
    return useRequestOffice('get', `${resource}/events`, { params })
  },
  postEvent(event) {
    return useRequestOffice('post', `${resource}/events`, event)
  },
  putEvent(id, event) {
    return useRequestOffice('put', `${resource}/events/${id}`, event)
  },
  deleteEvent(id) {
    return useRequestOffice('delete', `${resource}/events/delete/${id}`)
  },
})
