const initialState = {
  discarded: {
    data: [],
    paginate: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
  },
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_DISCARD(state, { type, value }) {
    state[type] = value
  },
  DELETE_ELEMENT_DISCARD(state, id) {
    const indexDelete = state.discarded.data.findIndex((item) => item.id === id)

    if (indexDelete >= 0) {
      state.discarded.data.splice(indexDelete, 1)
      state.discarded.paginate.total -= 1
    }
  },
  ADD_ELEMENT_DISCARD(state, element) {
    if (state.discarded.data.length < 15) {
      state.discarded.data.push(element)
    }

    state.discarded.paginate.total += 1
  },
}

export const actions = {
  async fetchDiscarded({ commit }, payload) {
    const { offerId, params } = payload

    const response =
      await this.$repository.v3.partnership.offers.getDiscardMatchUserWorkOffers(
        offerId,
        params
      )

    if (!response.success) {
      commit('SET_STATE_DISCARD', {
        type: 'discarded',
        value: { ...initialState.discarded },
      })
    }

    if (response.success) {
      const dataSearch = {
        data: response.result.data,
        paginate: response.result.paginate,
      }

      commit('SET_STATE_DISCARD', {
        type: 'discarded',
        value: dataSearch,
      })
    }

    return response
  },
}
