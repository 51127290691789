import { notification } from 'ant-design-vue'

const initialState = {
  totalInteresting: 0,
  leads: [],
  lead: null,
  statuses: [],
  notes: [],
}

export const state = () => ({ ...initialState })

export const getters = {
  leadStatusName: (state) => (id) => {
    if (!id) return '-'
    if (state.statuses.length === 0) return '-'

    const findState = state.statuses.find((status) => status.id === id)

    if (!findState) {
      return state.statuses[0].name
    }

    return findState.name
  },
}

export const mutations = {
  SET_STATE(state, { type, value }) {
    state[type] = value
  },
  ADD_NOTE(state, { value }) {
    state.notes.unshift(value)
  },
  UPDATE_NOTE(state, payload) {
    const indexUpdate = state.notes.findIndex((item) => item.id === payload.id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1, payload)
    }
  },
  DELETE_NOTE(state, id) {
    const indexUpdate = state.notes.findIndex((item) => item.id === id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1)
    }
  },
}

export const actions = {
  async fetchLeads({ state, commit }, params) {
    const response = await this.$repository.v3.interestingLeads.leads.getLeads(
      params
    )
    if (response.success) {
      commit('SET_STATE', {
        type: 'leads',
        value: response.result.data,
      })
    }
    return response
  },
  async fetchInterestingLeads({ state, commit }) {
    if (state.totalInteresting > 0) return state.totalInteresting
    const response =
      await this.$repository.v3.interestingLeads.leads.getTotalInterestingLeads()
    commit('SET_STATE', {
      type: 'totalInteresting',
      value: response.result,
    })
    return response
  },
  async fetchStatus({ state, commit }) {
    if (state.statuses.length !== 0) return state.statuses
    const response =
      await this.$repository.v3.interestingLeads.leads.getStatus()
    if (response.success) {
      commit('SET_STATE', {
        type: 'statuses',
        value: response.result.status,
      })
    }
    return response
  },
  async changeStatus({ commit }, params) {
    return await this.$repository.v3.interestingLeads.leads.postChangeStatus(
      params
    )
  },
  getLead({ commit }, params) {
    commit('SET_STATE', {
      type: 'lead',
      value: params,
    })
  },
  getNotes({ commit }, leadId) {
    commit('SET_STATE', {
      type: 'notes',
      value: [],
    })
    return this.$repository.v3.interestingLeads.leads
      .getNotes(leadId)
      .then((res) => {
        commit('SET_STATE', {
          type: 'notes',
          value: res.data.result,
        })
        return res.data.result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createNote({ commit }, params) {
    return this.$repository.v3.interestingLeads.leads
      .postNote(params)
      .then((res) => {
        commit('ADD_NOTE', {
          value: res.data.result.note,
        })
        return {
          status: res.status,
          data: res.data.success,
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.data.message,
        })
        return err
      })
  },
  updateNote({ commit }, payload) {
    return this.$repository.v3.interestingLeads.leads
      .postUpdateNote(payload)
      .then(({ data }) => {
        commit('UPDATE_NOTE', data.result.note)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ commit }, payload) {
    return this.$repository.v3.interestingLeads.leads
      .postDeleteNote(payload)
      .then(({ data }) => {
        commit('DELETE_NOTE', payload.note_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
