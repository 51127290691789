export const state = () => ({
  applications: [],
  applications_user: [],
  kips: null,
})

export const getters = {
  geKips: (state) => {
    if (!state.kips) return []
    const arrayKips = Object.values(state.kips)
    return arrayKips.slice().sort((a, b) => a.id - b.id)
  },
}

export const mutations = {
  SET_STATE_APPLICATIONS(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  getApplications({ commit }, params) {
    return this.$repository.mentor.application
      .postGetTable(params)
      .then(({ data }) => {
        const dataArray = data.data.table
        const newArray = []
        Object.keys(dataArray).forEach((item) => {
          const objectItem = {
            id_user: 0,
            user_name: 0,
            id_1: 0,
            id_2: 0,
            id_3: 0,
            id_4: 0,
            id_5: 0,
            id_6: 0,
            id_7: 0,
            id_8: 0,
          }

          objectItem.id_user = dataArray[item].user_id
          objectItem.user_name = dataArray[item].user_name

          const phases = dataArray[item].phases

          Object.keys(phases).forEach((phasesName) => {
            objectItem[`id_${phases[phasesName].id}`] = phases[phasesName].count
          })

          newArray.push(objectItem)
        })
        commit('SET_STATE_APPLICATIONS', {
          type: 'applications',
          data: newArray,
        })
        return data
      })
  },
  getKpis({ commit }, params) {
    return this.$repository.mentor.application
      .postGetKpis(params)
      .then(({ data }) => {
        commit('SET_STATE_APPLICATIONS', {
          type: 'kips',
          data: data.data.kpis,
        })
        return data
      })
  },
  getApplicationsUser({ commit }, params) {
    return this.$repository.mentor.application
      .postGetTableUser(params)
      .then(({ data }) => {
        commit('SET_STATE_APPLICATIONS', {
          type: 'applications_user',
          data: data.data.table,
        })
        return data
      })
  },
}
