
export default {
  name: 'LeadsInterestedCount',
  data: () => ({
    count: 0,
  }),
  mounted() {
    return this.$repository.mentor.v2.support
      .getTotalLeads()
      .then(({ data }) => {
        this.count = data.data
      })
  },
}
