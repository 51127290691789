import { notification } from 'ant-design-vue'

export const state = () => ({
  logs: [],
  talents: [],
  pagination: {},
})

export const mutations = {
  SET_STATE_CUSTOMER_SUCCESS(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  getLogsApplicant({ commit }, id) {
    return this.$repository.v3.admission.applicant
      .getLogsApplicant(id)
      .then(({ data }) => {
        commit('SET_STATE_CUSTOMER_SUCCESS', {
          type: 'logs',
          value: data.result,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getTalents({ commit }, params) {
    return this.$repository.v3.customerSuccessV3
      .getTalents(params)
      .then((res) => {
        const { result } = res.data
        commit('SET_STATE_CUSTOMER_SUCCESS', {
          type: 'talents',
          value: result.data,
        })
        commit('SET_STATE_CUSTOMER_SUCCESS', {
          type: 'pagination',
          value: {
            pageSize: result.per_page,
            current: result.current_page,
            total: result.total,
          },
        })
        return result
      })
      .catch(({ data }) => {
        if (!data) return data
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  revertPlacement({ dispatch }, params) {
    return this.$repository.v3.customerSuccessV3
      .postRevertPlacement(params)
      .then((res) => {
        dispatch('getTalents')
        return res.data
      })
      .catch(({ data }) => {
        if (!data) return data
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
