import { useRequestOffice } from '~/repositories'
const rootPath = '/api/v4/business-sales/business-prospects'

export default () => ({
  getLeads(params) {
    return useRequestOffice('get', `${rootPath}`, { params })
  },
  patchSalesExecutive(idLead, body) {
    return useRequestOffice(
      'patch',
      `${rootPath}/${idLead}/sales-executive`,
      body
    )
  },
  postCreateLead(body) {
    return useRequestOffice('post', `${rootPath}`, body)
  },
  putUpdateLead(idLead, body) {
    return useRequestOffice('put', `${rootPath}/${idLead}`, body)
  },
  /* Detalle de prospectos */
  getLeadById(idLead) {
    return useRequestOffice('get', `${rootPath}/${idLead}`)
  },
  postCreateMeeting(idLead, body) {
    return useRequestOffice('post', `${rootPath}/${idLead}/meetings`, body)
  },
  patchUpdateAssistance(idLead, body) {
    return useRequestOffice(
      'patch',
      `${rootPath}/${idLead}/meetings/attended`,
      body
    )
  },
  postCreateInterview(idLead, body) {
    return useRequestOffice('post', `${rootPath}/${idLead}/interviews`, body)
  },
  patchUpdateNumberOfVacancies(idLead, body) {
    return useRequestOffice(
      'patch',
      `${rootPath}/${idLead}/update-number-of-vacancies`,
      body
    )
  },
  patchUpdateOwner(idLead, body) {
    return useRequestOffice('patch', `${rootPath}/${idLead}/update-owner`, body)
  },
  patchChangeStatus(idLead, newStatus, body) {
    return useRequestOffice(
      'patch',
      `${rootPath}/${idLead}/change-status/${newStatus}`,
      body
    )
  },
  putUpdateLeadUtms(idLead, body) {
    return useRequestOffice('put', `${rootPath}/${idLead}/utms`, body)
  },
})
