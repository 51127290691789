import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/mentor/weeks'

export default ($axios) => ({
  getSessions() {
    return useRequestOffice('get', `${resource}/settings`)
  },
  deleteSession(id) {
    return useRequestOffice('delete', `${resource}/settings/${id}`)
  },
  postSession(newSession) {
    return useRequestOffice('post', `${resource}/settings`, newSession)
  },
  putSession(id, newSession) {
    return useRequestOffice('put', `${resource}/settings/${id}`, newSession)
  },
  getAttendace(weekId) {
    return $axios.get(`${resource}/${weekId}/attendace`)
  },
  postUpdateAttendace(weekId, attendace) {
    return $axios.post(`${resource}/${weekId}/attendace`, {
      attendace,
    })
  },
})
