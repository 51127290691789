const initialState = {
  objectives: [],
  pagination: {
    total: 1,
    pageSize: 10,
    current: 1,
  },
  type_objective: [],
  type_target: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_OBJECTIVE(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getObjectives({ commit }, params) {
    const response = await this.$repository.v3.admin.objectives.getObjectives(
      params
    )
    if (response.success) {
      commit('SET_STATE_OBJECTIVE', {
        type: 'objectives',
        data: response.result.data,
      })
      commit('SET_STATE_OBJECTIVE', {
        type: 'pagination',
        data: {
          pageSize: response.result.per_page,
          current: response.result.current_page,
          total: response.result.total,
        },
      })
    } else {
      commit('SET_STATE_OBJECTIVE', {
        type: 'objectives',
        data: [],
      })
    }
    return response
  },
  async getTypeObjective({ commit }, params) {
    const response =
      await this.$repository.v3.admin.objectiveTypes.getTypeObjective(params)
    if (response.success) {
      commit('SET_STATE_OBJECTIVE', {
        type: 'type_objective',
        data: response.result,
      })
    } else {
      commit('SET_STATE_OBJECTIVE', {
        type: 'type_objective',
        data: [],
      })
    }
    return response
  },
  async getTargetObjective({ commit }, params) {
    const response =
      await this.$repository.v3.admin.objectiveTypes.getTargetObjective(params)
    if (response.success) {
      commit('SET_STATE_OBJECTIVE', {
        type: 'type_target',
        data: response.result,
      })
    } else {
      commit('SET_STATE_OBJECTIVE', {
        type: 'type_target',
        data: [],
      })
    }
    return response
  },
  async deleteObjective({ dispatch }, id) {
    const response = await this.$repository.v3.admin.objectives.deleteObjective(
      id
    )
    if (response.success) {
      await dispatch('getObjectives')
    }
    return response
  },
  async createObjective({ dispatch }, newObjective) {
    const response = await this.$repository.v3.admin.objectives.postObjective(
      newObjective
    )
    if (response.success) {
      await dispatch('getObjectives')
    }
    return response
  },
  async updateObjective({ dispatch }, { id, newObjective }) {
    const response = await this.$repository.v3.admin.objectives.putObjective(
      id,
      newObjective
    )
    if (response.success) {
      await dispatch('getObjectives')
    }
    return response
  },
}
