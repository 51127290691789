export const state = () => ({
  courses: [],
})

export const mutations = {
  SET_STATE_COURSE(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getCourses({ commit }, params) {
    const { result, success } = await this.$repository.apiV3.getCourses(params)
    if (success) {
      commit('SET_STATE_COURSE', {
        type: 'courses',
        data: result,
      })
    } else {
      commit('SET_STATE_COURSE', {
        type: 'courses',
        data: [],
      })
    }
    return result
  },
  async deleteCourse({ dispatch }, id) {
    const { result, success } = await this.$repository.apiV3.deleteCourse(id)
    if (success) {
      await dispatch('getCourses')
    }
    return result
  },
  async createCourse({ dispatch }, newCourse) {
    const { result, success } = await this.$repository.apiV3.postCourse(
      newCourse
    )
    if (success) {
      await dispatch('getCourses')
    }
    return result
  },
  async updateCourse({ dispatch }, { id, newCourse }) {
    const { result, success } = await this.$repository.apiV3.putCourse(
      id,
      newCourse
    )
    if (success) {
      await dispatch('getCourses')
    }
    return result
  },
}
