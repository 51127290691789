
import { mapState, mapActions } from 'vuex'

export default {
  name: 'RequestPendingCount',
  computed: {
    ...mapState('collection/requests', ['requestsPendingCount']),
  },
  async created() {
    await this.getPendingRequestsTotal()
  },
  methods: {
    ...mapActions('collection/requests', ['getPendingRequestsTotal']),
  },
}
