const initialState = {
  interview: null,
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_MATCH_USER(state, { type, value }) {
    state[type] = value
  },
  SET_INTERVIEW(state, interviews) {
    state.interview =
      interviews.length > 0 ? interviews[interviews.length - 1] : null
  },
}

export const actions = {
  async createInterview({ dispatch }, { idUser, body, idOffer, bodyStatus }) {
    const response = await this.$repository.v3.partnership.offers.postInterview(
      idOffer,
      idUser,
      body
    )

    if (response.success && bodyStatus) {
      await this.$repository.v3.partnership.offers.putStatusCandidate(
        idOffer,
        idUser,
        bodyStatus
      )
    }

    return response
  },
}
