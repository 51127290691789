import { useRequestOffice } from '~/repositories'
const resource = '/mentor/applicants'

export default ($axios) => ({
  // FILTER
  getApplicantsFilter(params) {
    return useRequestOffice('get', `${resource}/filter`, { params })
  },
  getApplicantsFilterCollection(params) {
    return $axios.get(`${resource}/filter_to_collection`, { params })
  },

  // CRUD APPLICANT
  getApplicant(id) {
    return useRequestOffice('get', `${resource}/${id}`)
  },
  postCreateApplicant(params) {
    return useRequestOffice('post', `${resource}/create-applicant`, params)
  },
  postApplicant(params) {
    return useRequestOffice('post', `${resource}/update`, params)
  },

  // APLICANT
  getPaymentResources() {
    return useRequestOffice('get', `${resource}/get_payment_resources`)
  },
  postApplicantData(params) {
    return useRequestOffice('post', `${resource}/set_admission_data`, params)
  },
  postPlaceabiltyIindex(params) {
    return useRequestOffice('post', `${resource}/update-placeability`, params)
  },

  // CAMBIOS DE ESTADOS DEL APLICANTE
  postInterview(params) {
    return useRequestOffice('post', `${resource}/start_interview`, params)
  },
  postAdmitApplicant(params) {
    return useRequestOffice('post', `${resource}/admit_applicant`, params)
  },
  postApplicantPayment(params) {
    return useRequestOffice('post', `${resource}/set_applicant_payment`, params)
  },
  postInterviewNotDone(params) {
    return useRequestOffice('post', `${resource}/interview_not_done`, params)
  },
  postRejectApplicant(params) {
    return useRequestOffice('post', `${resource}/reject_applicant`, params)
  },

  // Pagos
  postGetFullPayments(params) {
    return $axios.post(`${resource}/get_payments_with_logs`, params)
  },
  postUpdateApplicantPayment(params) {
    return $axios.post(`${resource}/update-admission-data`, params)
  },
  postGetPaymentLogs(params) {
    return $axios.post(`${resource}/get_payment_logs`, params)
  },

  // Datos de entrevista
  postGetInterviewDataApplicant(params) {
    return useRequestOffice('post', `${resource}/info-interview-data`, params)
  },
  getPaymentQuotas() {
    return useRequestOffice('get', `${resource}/payment-quotas`)
  },

  // NOTES
  postGetNotes(params) {
    return $axios.post(`${resource}/get_notes`, params)
  },
  postCreateNote(params) {
    return $axios.post(`${resource}/notes`, params)
  },
  postUpdateNote(params) {
    return $axios.post(`${resource}/edit_notes`, params)
  },
  postDeleteNote(params) {
    return $axios.post(`${resource}/delete_notes`, params)
  },

  // TypeForm
  getApplicantsTypeform(params) {
    return $axios.get(`${resource}/initiative-typeforms`, { params })
  },
  getFiltersTypeform() {
    return $axios.get(`${resource}/initiative-typeforms/filters`)
  },
  postQualifyTypeformApplicants(type = 'approve', params) {
    return $axios.post(`${resource}/initiative-typeforms/${type}`, params)
  },

  // PAYMENTS
  postPayManually(params) {
    return $axios.post(`${resource}/pay_fee_manually`, params)
  },
  postConfirmPayment(params) {
    return $axios.post(`${resource}/confirm_payment`, params)
  },
  postUpdatePayment(params) {
    return $axios.post(`${resource}/update_payment`, params)
  },
  postDeletePayment(params) {
    return $axios.post(`${resource}/delete_payment`, params)
  },

  postGetApplicant(params) {
    return $axios.post(`${resource}/find_with_payments`, params)
  },
  postGetEmulatePaymentSchedule(params) {
    return $axios.post(
      `${resource}/emulate/schedule-payments/success-fee`,
      params
    )
  },
  postSavedPaymentSchedule(params) {
    return $axios.post(
      `${resource}/saved/schedule-payments/success-fee`,
      params
    )
  },
  postSendEmailPaymentSchedule(params) {
    return $axios.post(
      `${resource}/email/schedule-payments/success-fee`,
      params
    )
  },

  getActiveCampaignId(id) {
    return $axios.get(`${resource}/${id}/active-campaign-id`)
  },
})
