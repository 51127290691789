
import { mapState } from 'vuex'
import ItemSearch from '~/components/layout/global-search/ItemSearch.vue'
import CategorySearch from '~/components/layout/global-search/CategorySearch.vue'

export default {
  name: 'GlobalSearch',
  components: { CategorySearch, ItemSearch },
  data: () => ({
    visible: false,
    search: '',
    listOffers: [],
    loadingOffers: false,
    listUsers: [],
    loadingUsers: false,
    listCompanies: [],
    loadingCompanies: false,
    debounceTimer: null,
    initialView: true,
  }),
  computed: {
    ...mapState(['collapsedSidebar']),
    isLoading() {
      return this.loadingOffers || this.loadingUsers || this.loadingCompanies
    },
    isEmpty() {
      return (
        !this.listOffers.length &&
        !this.listUsers.length &&
        !this.listCompanies.length
      )
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.preventDefault()
        this.visible = true
      }
    },
    async fetchSearchOffers(search) {
      this.loadingOffers = true

      const response =
        await this.$repository.v3.partnership.offers.getWorkOffers({
          search,
        })

      if (!response.success) {
        this.listOffers = []
        this.loadingOffers = false
        return
      }

      this.listOffers = response.result.data.map((item) => {
        const link = `/everest/offer/${item.id}/processes/`
        const avatar =
          item.logo_path ||
          'https://cdn.talently.tech/avatar-offer_1724994831846.svg'

        const meta = [
          item.business?.name,
          item.work_offer_status?.name,
          item.work_modality?.name,
        ]

        if (item.published_at) {
          meta.push(this.$moment(item.published_at).fromNow())
        }

        return {
          id: item.id,
          link,
          avatar,
          name: item.name,
          meta,
        }
      })

      this.loadingOffers = false
    },
    async fetchSearchUsers(search) {
      this.loadingUsers = true
      const response = await this.$repository.v3.partnership.matchUser.getUsers(
        { search }
      )

      if (!response.success) {
        this.listUsers = []
        this.loadingUsers = false
        return
      }

      this.listUsers = response.result.data.map((item) => {
        const link = `/everest/users/${item.id}/`
        const avatar =
          item.logo_path ||
          'https://cdn.talently.tech/avatar-user_1724994831846.svg'

        const meta = [item.email, item.positions[0]]

        return {
          id: item.id,
          link,
          avatar,
          name: item.full_name,
          meta,
          cv: item.cv,
        }
      })

      this.loadingUsers = false
    },
    async fetchSearchCompanies(search) {
      this.loadingCompanies = true

      const response =
        await this.$repository.v3.partnership.businesses.getBusinesses({
          search,
        })

      if (!response.success) {
        this.listCompanies = []
        this.loadingCompanies = false
        return
      }

      this.listCompanies = response.result.data.map((item) => {
        const link = `/sales/business/${item.id}/`
        const avatar =
          item.logo_path ||
          'https://cdn.talently.tech/avatar-hire_1724994831846.svg'

        const meta = [
          item.email,
          item.subscription_available?.label,
          item.world_country?.name,
        ]

        return {
          id: item.id,
          link,
          avatar,
          name: item.name,
          meta,
          link_platform: item.administrator?.token_auth_url,
        }
      })

      this.loadingCompanies = false
    },
    async onSearch() {
      await Promise.allSettled([
        this.fetchSearchOffers(this.search),
        this.fetchSearchUsers(this.search),
        this.fetchSearchCompanies(this.search),
      ])

      this.initialView = false
    },
    onInput() {
      clearTimeout(this.debounceTimer)

      this.debounceTimer = setTimeout(() => {
        if (this.search.length) {
          this.onSearch()
        }
      }, 300)
    },
    openItemSearch() {
      this.visible = false
    },
  },
}
