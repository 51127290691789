// Estados para el kanban
export const PENDING_TO_APPROVED_ID = 5

export const NEGOTIATION_ID = 6

export const APPROVED_ID = 7

export const CONTENT_CURATION_ID = 8

export const DRAFT_HIRE_ID = 9

export const REJECTED_ID = 10

export const ACTIVE_ID = 1

export const PUBLISHED_ID = 1

export const CONFIG_KANBAN = [
  {
    id: PENDING_TO_APPROVED_ID,
    color: 'bg-purple-700',
    name: 'Ofertas por aprobar',
  },
  {
    id: NEGOTIATION_ID,
    color: 'bg-yellow-500',
    name: 'Negociación',
  },
  {
    id: APPROVED_ID,
    color: 'bg-blue-500',
    name: 'Aprobadas por TRS',
  },
  {
    id: CONTENT_CURATION_ID,
    color: 'bg-green-400',
    name: 'Curación de contenido',
  },
  {
    id: PUBLISHED_ID,
    color: 'bg-orange-500',
    name: 'Publicadas',
  },
  {
    id: REJECTED_ID,
    color: 'bg-red-500',
    name: 'Rechazadas',
  },
]

export const CONFIG_KANBAN_PRO = [
  {
    id: PENDING_TO_APPROVED_ID,
    color: 'bg-purple-700',
    name: 'Ofertas por aprobar',
  },
  {
    id: NEGOTIATION_ID,
    color: 'bg-yellow-500',
    name: 'Negociación',
  },
  {
    id: APPROVED_ID,
    color: 'bg-blue-500',
    name: 'Aprobadas por TRS',
  },
  {
    id: CONTENT_CURATION_ID,
    color: 'bg-green-400',
    name: 'Curación de contenido',
  },
  {
    id: PUBLISHED_ID,
    color: 'bg-orange-500',
    name: 'Publicadas',
  },
]

export const CONFIG_KANBAN_LITE_FREE = [
  {
    id: PENDING_TO_APPROVED_ID,
    color: 'bg-purple-700',
    name: 'Ofertas por aprobar',
  },
  {
    id: PUBLISHED_ID,
    color: 'bg-orange-500',
    name: 'Publicadas',
  },
  {
    id: REJECTED_ID,
    color: 'bg-red-500',
    name: 'Rechazadas',
  },
]
