export const SUGGESTIONS_ID = 5

export const CANDIDATES_ID = 1

export const PREFILTER_ID = 7

export const PRE_FILTER_APPROVED_ID = 8

export const SENT_TO_COMPANY_ID = 2

export const COMPANY_APPROVED_ID = 9

export const COMPANY_INTERVIEW_ID = 10

export const DISCARD_ID = 11

export const PLACED_ID = 3

export const REJECTED_ID = 4

export const TECHNICAL_INTERVIEW = 12

export const FINAL_STAGE = 13

export const PRE_PLACED_ID = 14

export const CONFIG_KANBAN = [
  {
    id: CANDIDATES_ID,
    add_user: true,
    show_filter: false,
    color: 'bg-purple-700',
    name: 'Candidatos',
  },
  {
    id: SENT_TO_COMPANY_ID,
    add_user: false,
    show_filter: false,
    color: 'bg-indigo-700',
    name: 'Enviado a la empresa',
  },
  {
    id: COMPANY_APPROVED_ID,
    add_user: false,
    show_filter: false,
    color: 'bg-yellow-500',
    name: 'Aceptado por empresa',
  },
  {
    id: COMPANY_INTERVIEW_ID,
    add_user: false,
    show_filter: false,
    color: 'bg-purple-400',
    name: 'Entrevista inicial',
  },
  {
    id: TECHNICAL_INTERVIEW,
    add_user: false,
    show_filter: false,
    color: 'bg-pink-600',
    name: 'Entrevista técnica',
  },
  {
    id: FINAL_STAGE,
    add_user: false,
    show_filter: false,
    color: 'bg-blue-500',
    name: 'Etapa final',
  },
  {
    id: PRE_PLACED_ID,
    add_user: false,
    show_filter: false,
    color: 'bg-linkedin',
    name: 'Pre Contrato',
  },
  {
    id: PLACED_ID,
    add_user: false,
    show_filter: false,
    color: 'bg-green-400',
    name: 'Placed',
  },
]
