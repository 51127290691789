import { useRequestOffice } from '~/repositories'
export default () => ({
  getTalents(params) {
    return useRequestOffice('get', '/api/v3/customer-success/success-fee', {
      params,
    })
  },
  postChargeFee(id) {
    return useRequestOffice(
      'post',
      '/api/v3/customer-success/success-fee/charge-payment',
      {
        user_success_fee_payment_id: id,
      }
    )
  },
  postUpdateStatusFee(id) {
    return useRequestOffice(
      'post',
      '/api/v3/customer-success/success-fee/update-payment-status',
      {
        user_success_fee_payment_id: id,
      }
    )
  },
  postUpdatePayments(id, payments, amount_total) {
    return useRequestOffice(
      'post',
      `/api/v3/customer-success/success-fee/${id}/update-schedule`,
      {
        payments,
        amount_total,
      }
    )
  },
  getDeliverableNotifications(userId) {
    return useRequestOffice(
      'get',
      `/api/v3/customer-success/user/${userId}/settings/deliverable-notifications`
    )
  },
  postDeletePaymentsSchedule(id) {
    return useRequestOffice(
      'post',
      `/api/v3/customer-success/success-fee/${id}/delete-schedule`
    )
  },
})
