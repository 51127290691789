import { responseStore } from '~/utils/store'

const initialState = {
  offer: null,
  currentTab: '',
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_OFFER(state, { type, value }) {
    state[type] = value
  },
  UPDATE_KEY_OFFER(state, { type, value }) {
    state.offer[type] = value
  },
}

export const actions = {
  async fetchOfferId({ state, commit }, { offerId, force = false }) {
    if (state.offer !== null && state.offer.id === offerId && force === false) {
      return responseStore(state.offer)
    }

    const response =
      await this.$repository.v3.partnership.offers.getWorkOfferById(offerId)

    if (response.success) {
      commit('SET_STATE_OFFER', {
        type: 'offer',
        value: response.result,
      })
    }

    return response
  },
  async createOffer({ dispatch, commit }, body) {
    const response = await this.$repository.v3.partnership.offers.postOfferById(
      body
    )

    if (response.success) {
      await dispatch('fetchOfferId', {
        offerId: response.result.id,
        force: true,
      })
    }

    return response
  },
  async updateOffer({ dispatch }, { idOffer, body }) {
    const response = await this.$repository.v3.partnership.offers.putOfferById(
      idOffer,
      body
    )

    if (response.success) {
      await dispatch('fetchOfferId', { offerId: idOffer, force: true })
    }

    return response
  },
  async updateSkillsOffer({ dispatch }, { idOffer, body }) {
    const response =
      await this.$repository.v3.partnership.offers.putUpdateTabProfesionalProfile(
        idOffer,
        body
      )

    if (response.success) {
      await dispatch('fetchOfferId', { offerId: idOffer, force: true })
    }

    return response
  },
  async updateDetailsOffer({ dispatch }, { idOffer, body }) {
    const response =
      await this.$repository.v3.partnership.offers.putUpdateTabCompanyProfile(
        idOffer,
        body
      )

    if (response.success) {
      await dispatch('fetchOfferId', { offerId: idOffer, force: true })
    }

    return response
  },
  async updateStatusOffer({ commit, rootState }, { idOffer, body }) {
    const response =
      await this.$repository.v3.partnership.offers.putUpdateStatus(
        idOffer,
        body
      )

    if (response.success) {
      const newIdStatus = body.work_offer_status_id

      const findStatus = rootState.support.resources.work_offer_statuses.find(
        (status) => status.id === newIdStatus
      )

      commit('UPDATE_KEY_OFFER', {
        type: 'work_offer_status',
        value: findStatus,
      })

      commit('UPDATE_KEY_OFFER', {
        type: 'work_offer_status_id',
        value: newIdStatus,
      })
    }

    return response
  },
  async closeOffer({ dispatch }, { idOffer, body }) {
    const response =
      await this.$repository.v3.partnership.offers.postCloseOffers(
        idOffer,
        body
      )

    if (response.success) {
      await dispatch('fetchOfferId', { offerId: idOffer, force: true })
    }

    return response
  },
}
