const resource = '/mentor/v2/deliverables'

export default ($axios) => ({
  getAll(params) {
    return $axios.get(`${resource}/all-paginate`, {
      params,
    })
  },
  postSendFeedback(payload) {
    return $axios.post(`${resource}/feedback`, payload)
  },
})
