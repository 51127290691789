import { useRequestOffice } from '~/repositories'
const resource = 'api/v3/admission/match-users'

export default () => ({
  rejectMatchUserRegister(matchUserId) {
    return useRequestOffice('post', `${resource}/${matchUserId}/reject`)
  },
  acceptMatchUserRegister(matchUserId) {
    return useRequestOffice('post', `${resource}/${matchUserId}/accept`)
  },
})
