import status from '~/assets/data/user_status.json'

export const state = () => ({
  talents: [],
  count_deliverables: 4,
  pagination: {},
})

export const mutations = {
  SET_STATE_TALENTS(state, { type, data }) {
    state[type] = data
  },
  SET_ONBOARDING(state, payload) {
    const indexTalents = state.talents.findIndex(
      (item) => item.id === payload.user_id
    )
    if (indexTalents >= 0)
      state.talents[indexTalents].onboarding_session = payload.value
  },
  SET_STATUS_TALENTS(state, payload) {
    const { userID, userStatusId } = payload
    const findStatus = status.find((item) => item.id === userStatusId)
    const indexTalents = state.talents.findIndex((item) => item.id === userID)
    if (findStatus && indexTalents >= 0)
      state.talents[indexTalents].status = findStatus.name
  },
}

export const actions = {
  getTalents({ commit }, params) {
    return this.$repository.mentor.v2.home.postGetTable(params).then((res) => {
      const {
        data: { deliverables, table },
      } = res.data

      commit('SET_STATE_TALENTS', {
        type: 'count_deliverables',
        data: deliverables,
      })
      commit('SET_STATE_TALENTS', {
        type: 'pagination',
        data: {
          pageSize: table.per_page,
          current: table.current_page,
          total: table.total,
        },
      })

      const tableData = table.data.map((item, index) => {
        return {
          key: index,
          ...item,
        }
      })

      commit('SET_STATE_TALENTS', {
        type: 'talents',
        data: tableData,
      })

      return res.data
    })
  },
}
