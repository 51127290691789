import { useRequestOffice } from '~/repositories'
const rootPath = '/mentor/user_profile'

export default () => ({
  getNotes(payload) {
    return useRequestOffice('post', `${rootPath}/notes/show`, payload)
  },
  postCreateNote(payload) {
    return useRequestOffice('post', `${rootPath}/notes/create`, payload)
  },
  postUpdateNote(payload) {
    return useRequestOffice('post', `${rootPath}/notes/edit`, payload)
  },
  postDeleteNote(payload) {
    return useRequestOffice('post', `${rootPath}/notes/delete`, payload)
  },
})
