import { useRequestOffice } from '~/repositories'

export default () => ({
  getSupportResources(params) {
    return useRequestOffice('get', `/api/v3/support/office/resources`, {
      params,
    })
  },
  getCities(params) {
    return useRequestOffice('get', `/api/v3/support/office/cities`, {
      params,
    })
  },
  migrateBlend(userId) {
    return useRequestOffice('post', `/api/v3/support/user/${userId}/migrate`)
  },
})
