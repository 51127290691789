import { useRequestOffice } from '~/repositories'
const admissionV3 = '/api/v3/admission/applicants'

export default ($axios) => ({
  // APPLICANT
  postUpdateApplicantInterviewData(params) {
    return $axios.post(
      `${admissionV3}/${params.applicant_id}/interview-data`,
      params
    )
  },
  getCategories() {
    return $axios.get(`${admissionV3}/rejection-categories`)
  },
  postResetApplicantStatus(applicantId) {
    return $axios.post(`${admissionV3}/${applicantId}/reset-status`)
  },

  // CAMBIOS DE ESTADOS DE APPLICANTE
  postRejectPreScreeningApplicant(params) {
    return $axios.post(`${admissionV3}/reject-prescreening`, params)
  },
  postCorrectApplicant(params) {
    return $axios.post(`${admissionV3}/correct-prequalified`, params)
  },
  postRestoreApplicant(params) {
    return $axios.post(`${admissionV3}/restore-prescreening`, params)
  },
  postLostApplicant(params) {
    return $axios.post(`${admissionV3}/change-status/lost-applicant`, params)
  },

  // Utms
  getApplicantUtmLogs(applicantId) {
    return useRequestOffice(
      'get',
      `${admissionV3}/${applicantId}/utm-campaigns`
    )
  },

  // ContactLater
  postContactLater(params) {
    return $axios.post(`${admissionV3}/contact-later`, params)
  },

  getRepoPreApplicants(params) {
    return $axios.get(`${admissionV3}`, { params })
  },
  postApprovePreApplicants(params) {
    return $axios.post(`${admissionV3}/approve-prequalified`, params)
  },
  postRejectPreApplicants(params) {
    return $axios.post(`${admissionV3}/reject-prequalified`, params)
  },
})
