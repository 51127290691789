
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Count',
  computed: {
    ...mapState('admission/interestingLeads', ['totalInteresting']),
  },
  mounted() {
    this.fetchInterestingLeads()
  },
  methods: {
    ...mapActions('admission/interestingLeads', ['fetchInterestingLeads']),
  },
}
