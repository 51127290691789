const initialState = {
  offers: [],
  pagination: {
    pageSize: 1,
    current: 1,
    total: 1,
  },
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_OFFERS(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchOffers({ commit }, params) {
    const response = await this.$repository.v3.partnership.offers.getWorkOffers(
      params
    )

    if (response.success) {
      const { data, paginate } = response.result

      commit('SET_STATE_OFFERS', {
        type: 'offers',
        value: data,
      })

      commit('SET_STATE_OFFERS', {
        type: 'pagination',
        value: {
          pageSize: paginate.per_page,
          current: paginate.current_page,
          total: paginate.total,
        },
      })
    }

    return response
  },
}
