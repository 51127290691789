import { customColumns } from '~/utils/admission/tableTypeform'

const initialState = {
  filtersTypeform: {
    qualification_statuses: [],
    typeform_titles: [],
  },
  applicants: [],
  rowsTypeform: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_APPLICANTS(state, { type, value }) {
    state[type] = value
  },
  UPDATE_APPLICANT(state, payload) {
    state.applicant = { ...state.applicant, ...payload }
  },
  UPDATE_KEY_APPLICANT(state, { key, value }) {
    state.applicant[key] = value
  },
}

export const actions = {
  // FILTER
  async getFiltersTypeform({ state, commit }) {
    if (state.filtersTypeform.qualification_statuses.length) return

    const { status, data } =
      await this.$repository.mentor.applicants.getFiltersTypeform()

    if (status === 200 && data.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'filtersTypeform',
        value: data.data,
      })
    }
    return data
  },
  getApplicants({ commit }, params) {
    return this.$repository.mentor.applicants
      .getApplicantsTypeform(params)
      .then(async ({ data }) => {
        const table = data.data.data
        commit('SET_STATE_APPLICANTS', { type: 'applicants', value: table })

        if (table.length) {
          const rowsTypeform = customColumns(table)
          await commit('SET_STATE_APPLICANTS', {
            type: 'rowsTypeform',
            value: rowsTypeform,
          })
        }

        return data
      })
      .catch(({ response }) => {
        return response.data
      })
  },
  qualifyTypeformApplicants(_, { type, params }) {
    return this.$repository.mentor.applicants
      .postQualifyTypeformApplicants(type, params)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        return response.data
      })
  },
}
