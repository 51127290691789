export default ({ $gtm }) => ({
  // sendErrorPhone(data) {
  //   const eventData = {
  //     Celular: data.phone,
  //     'Código de país': data.phone,
  //   }
  //
  //   $gtm.push({ event: 'Error de Celular', eventData })
  // },
  // sendErrorTimezone() {
  //   $gtm.push({ event: 'Error de Timezone' })
  // },
})
