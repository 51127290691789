export const state = () => ({
  coupons: {},
  pagination: {},
  coupon: {},
  promotion_codes: {},
  pagination_promotion_codes: {},
})

export const mutations = {
  SET_STATE_COUPONS(state, { type, value }) {
    state[type] = value
  },
  ADD_PROMOTION_CODE(state, promotionCode) {
    if (state.promotion_codes && state.promotion_codes.data) {
      state.promotion_codes.data.unshift(promotionCode)
    } else {
      state.promotion_codes = { data: [promotionCode] }
    }
  },
  ADD_COUPON(state, coupon) {
    if (state.coupons && state.coupons.data) {
      state.coupons.data.unshift(coupon)
    } else {
      state.coupons = { data: [coupon] }
    }
  },
}

export const actions = {
  async fetchCoupons({ commit }, data) {
    const response = await this.$repository.v4.sales.coupons.getCoupons({
      page: data.page,
      per_page: data.page,
    })
    if (response.success) {
      commit('SET_STATE_COUPONS', {
        type: 'coupons',
        value: { ...response.result },
      })

      commit('SET_STATE_COUPONS', {
        type: 'pagination',
        value: {
          pageSize: response.result.paginate.per_page,
          current: response.result.paginate.current_page,
          total: response.result.paginate.total,
        },
      })
    }
    return response
  },
  async fetchCouponById({ commit }, couponId) {
    const response = await this.$repository.v4.sales.coupons.getCoupon(couponId)
    if (response.success) {
      commit('SET_STATE_COUPONS', {
        type: 'coupon',
        value: response.result,
      })
    }
    return response
  },
  async fetchPromotionCodes({ commit }, data) {
    const response = await this.$repository.v4.sales.coupons.getPromotionCodes(
      data.coupon_id,
      { page: data.page, per_page: data.page }
    )
    if (response.success) {
      commit('SET_STATE_COUPONS', {
        type: 'promotion_codes',
        value: { ...response.result },
      })
      commit('SET_STATE_COUPONS', {
        type: 'pagination_promotion_codes',
        value: {
          pageSize: response.result.paginate.per_page,
          current: response.result.paginate.current_page,
          total: response.result.paginate.total,
        },
      })
    }
  },
}
