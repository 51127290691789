
import ItemSearch from '~/components/layout/global-search/ItemSearch.vue'

export default {
  components: { ItemSearch },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    listSearch() {
      return this.items.slice(0, 7)
    },
  },
  methods: {
    openItemSearch(link) {
      this.$router.push(link)
      this.$emit('click')
    },
  },
}
