export default ({ $gtm }) => ({
  // sendInterviewSuccess() {
  //   $gtm.push({ event: 'entrevista-realizada' })
  // },
  // sendInterviewNotConducted() {
  //   $gtm.push({ event: 'entrevista-no-realizada' })
  // },
  // sendAcceptedApplicant() {
  //   $gtm.push({ event: 'postulante_aceptado' })
  // },
})
