import { useRequestOffice } from '~/repositories'
const resource = '/api/v2/support'

export default ($axios) => ({
  getTimeZones() {
    return useRequestOffice('get', `${resource}/timezones`)
  },
  getFilterDeliverables() {
    return $axios.get(`${resource}/deliverables`, {
      platform_version_id: 2,
    })
  },
})
