const resource = 'api/v1/outreach/table'

export default ($axios) => ({
  postGetTable(payload) {
    return $axios.post(`${resource}`, null, { params: payload })
  },
  postGetTableFilters() {
    return $axios.post(`${resource}/form_fields_outreach`)
  },
  postUpdateTableCategory(params) {
    return $axios.post(`${resource}/store-categorization`, params)
  },
})
