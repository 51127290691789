
import { mapState } from 'vuex'
import FollowUpCount from '~/components/admission/applicants/FollowUpCount'
import RequestPendingCount from '~/components/collections/RequestPendingCount'
import CountInterestingLead from '~/components/admission/interestingLead/Count'
import DeliverablesCount from '~/components/mentor/deliverables/DeliverablesCount'
import LeadsInterestedCount from '~/components/admission/applicants/LeadsInterestedCount'
import TalentPlacementCompleted from '~/components/mentor/talents/TalentPlacementCompleted'

const officeMenu = [
  {
    name: 'Hire',
    link: '/hire/',
    icon: 'ic-baseline-apartment',
    children: [
      {
        name: 'Ofertas',
        link: '/everest/?work_offer_status_id=1',
        icon: 'ic-outline-business-center',
      },
      {
        name: 'Aprobación de ofertas',
        link: '/everest/management-offers/',
        icon: 'ic-outline-check-box',
      },
      {
        name: 'Empresas',
        link: '/everest/business/',
        icon: 'ic-outline-business',
      },
      {
        name: 'Prospectos',
        link: '/sales/prospectos/',
        icon: 'ic-baseline-ssid-chart',
      },
      /* {
        name: 'Cupones',
        link: '/hire/coupons/',
        icon: 'ic:baseline-discount',
      }, */
    ],
  },
  {
    name: 'Match',
    link: '/match/',
    icon: 'ic-outline-supervisor-account',
    children: [
      {
        name: 'Usuarios',
        link: '/everest/users/',
        icon: 'ic-outline-supervisor-account',
      },
      {
        name: 'Roles',
        link: '/everest/roles/',
        icon: 'ic-outline-assignment-ind',
      },
      {
        name: 'Habilidades',
        link: '/everest/skills/',
        icon: 'ic-baseline-directions-run',
      },
      {
        name: 'Vetting',
        link: 'https://hub-admin.talently.tech/matchs/questions',
        icon: 'ic-outline-fact-check',
      },
    ],
  },
  {
    name: 'Herramientas',
    link: '/tools/',
    icon: 'ic-outline-build',
    children: [
      {
        name: 'Subir archivos',
        link: '/tools/upload-files/',
        icon: 'ic-baseline-upload-file',
      },
      {
        name: 'Strapi',
        link: 'https://strapi.talently.tech/admin',
        icon: 'logos-strapi-icon',
      },
      {
        name: 'Stripe',
        link: 'https://dashboard.stripe.com/',
        icon: 'icons8:stripe',
      },
    ],
  },
  {
    name: 'GO (Deprecated)',
    link: '/go/',
    icon: 'ic-outline-rocket',
    children: [
      {
        name: 'Admission',
        link: '/admission/',
        children: [
          {
            name: 'Postulantes',
            link: '/admission/',
          },
          {
            name: 'Prospectos',
            link: '/admission/prospectos/',
            badge: LeadsInterestedCount,
          },
          {
            name: 'Leads Interesantes',
            link: '/admission/leads-interesantes/',
            badge: CountInterestingLead,
          },
          {
            name: 'Follow Up',
            link: '/admission/follow-up/',
            badge: FollowUpCount,
          },
          {
            name: 'Pagos completos',
            link: '/admission/full-payments/',
          },
          {
            name: 'Pre-Filtro Becas',
            link: '/admission/prefilter/',
          },
        ],
      },
      {
        name: 'Collection',
        link: '/collection/',
        children: [
          {
            name: 'Pagos pendientes',
            link: '/collection/',
          },
          {
            name: 'Pagos completos',
            link: '/collection/full-payments/',
          },
          {
            name: 'Contratos',
            link: '/collection/contracts/',
          },
          {
            name: 'Peticiones',
            link: '/collection/requests/',
            badge: RequestPendingCount,
          },
        ],
      },
      {
        name: 'Mentor',
        link: '/mentor/',
        children: [
          {
            name: 'Talents',
            link: '/mentor/talents/',
            badge: TalentPlacementCompleted,
          },
          {
            name: 'Entregables',
            link: '/mentor/deliverables/',
            badge: DeliverablesCount,
          },
        ],
      },
      {
        name: 'Learning',
        link: '/learning/',
        children: [
          {
            name: 'Semanas',
            link: '/learning/',
          },
          {
            name: 'Sesiones semanales',
            link: '/learning/week/sessions/',
          },
          {
            name: 'Link de eventos',
            link: '/learning/events-links/',
          },
        ],
      },
      {
        name: 'Customer Success',
        link: '/customer-success/',
        children: [
          {
            name: 'Talents',
            link: '/customer-success/',
          },
          {
            name: 'Success Fee',
            link: '/customer-success/success-fee/',
          },
        ],
      },
    ],
  },
]

export default {
  name: 'AdminMenu',
  data: () => ({
    collapsed: false,
    selectedKeys: ['/everest/?work_offer_status_id=1'],
    openKeys: [],
    menu: [],
  }),
  computed: {
    ...mapState(['collapsedSidebar']),
  },
  mounted() {
    if (!this.collapsedSidebar) {
      this.openKeys = ['/hire/', '/match/']
    }

    this.addKeyToMenu(officeMenu)
    this.menu = officeMenu
  },
  methods: {
    isURL(str) {
      try {
        return Boolean(new URL(str))
      } catch (error) {
        return false
      }
    },
    openLink(link) {
      if (this.isURL(link)) {
        window.open(link, '_blank')
        return
      }

      this.$router.push(link)
    },
    addKeyToMenu(array) {
      array.forEach((item) => {
        item.key = item.link

        if (item.children && item.children.length > 0) {
          this.addKeyToMenu(item.children)
        }
      })
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys
    },
    onClickMenu({ key }) {
      this.selectedKeys = [key]
    },
  },
}
