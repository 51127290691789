import { useRequestOffice } from '~/repositories'
const rootPath = '/api/v3/partnership/businesses/work-offers'

export default () => ({
  /* Kanban de candidatos */
  getOffersKanban(params) {
    return useRequestOffice('get', `${rootPath}/kanban`, { params })
  },
  getReasons(params) {
    return useRequestOffice('get', `${rootPath}/reasons/`, { params })
  },
  putApproveOffer(idOffer) {
    return useRequestOffice('put', `${rootPath}/${idOffer}/kanban/approve`)
  },
  putReasonNegotiation(idOffer, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/${idOffer}/kanban/negotiate`,
      body
    )
  },
  putContentCurationOffer(idOffer) {
    return useRequestOffice(
      'put',
      `${rootPath}/${idOffer}/kanban/content-curation`
    )
  },

  /** **** ENDPOINTS FALTANTES ******/
  putPublishOffer(idOffer, params) {
    return useRequestOffice(
      'put',
      `${rootPath}/${idOffer}/kanban/publish`,
      params
    )
  },

  /* OFERTAS DESCARTADAS */
  getRejectedOffers(params) {
    return useRequestOffice('get', `${rootPath}/kanban/rejected`, { params })
  },
  /* Restaurar Oferta */
  putRestoreOffer(idOffer) {
    return useRequestOffice('put', `${rootPath}/${idOffer}/kanban/restore`)
  },
  /* Eliminar Oferta */
  putRejectOffer(idOffer, body) {
    return useRequestOffice('put', `${rootPath}/${idOffer}/kanban/reject`, body)
  },

  putPendingOffer(idOffer) {
    return useRequestOffice(
      'put',
      `${rootPath}/${idOffer}/kanban/pending-to-approved`
    )
  },
})
