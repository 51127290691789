const initialState = {
  applicants: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_APPLICANTS(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  // FILTER
  getPreApplicants({ commit }, params) {
    return this.$repository.v3.admission.applicants
      .getRepoPreApplicants(params)
      .then(({ data }) => {
        commit('SET_STATE_APPLICANTS', {
          type: 'applicants',
          value: data.result.data,
        })
        return data
      })
      .catch(({ response }) => {
        return response.data
      })
  },
  approvePreApplicants(_, params) {
    return this.$repository.v3.admission.applicants
      .postApprovePreApplicants(params)
      .then(({ data }) => {
        return data
      })
      .catch(({ data }) => {
        return data
      })
  },
  rejectPreApplicants(_, params) {
    return this.$repository.v3.admission.applicants
      .postRejectPreApplicants(params)
      .then(({ data }) => {
        return data
      })
      .catch(({ data }) => {
        return data
      })
  },
}
