import { notification } from 'ant-design-vue'

const deliverable = {
  deliverable_label: '',
  user: {
    fullname: '',
  },
  stages: [],
  corrections: [],
}

export const state = () => ({
  mentors: [],
  select_mentor: {},
  list_session: [],
  select_tab: 'active',
  select_day: '',
  email_talent: '',
  group_session: {},
  attendace: [],
  week: {},
  notes: [],
  has_pending: false,
  list_postulation: [],
  list_deliverables: [],
  deliverable: { ...deliverable },
  loading_session: false,
})

export const mutations = {
  SET_STATE_MENTORS(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  getMentors({ commit }) {
    return this.$repository.v3.mentor.mentors.getMentors().then(({ data }) => {
      commit('SET_STATE_MENTORS', {
        type: 'mentors',
        data: data.result,
      })
      return data.result
    })
  },
  selectMentor({ commit }, payload) {
    commit('SET_STATE_MENTORS', {
      type: 'select_mentor',
      data: payload,
    })
  },
  getSessionByMentors({ commit, state }) {
    const day = state.select_day === 7 ? '' : `&day=${state.select_day}`
    const email =
      state.email_talent !== '' ? `&email=${state.email_talent}` : ''
    return this.$repository.v3.mentor.mentors
      .getSessionByMentors(
        state.select_mentor ? state.select_mentor.id : 1,
        state.select_tab,
        email,
        day,
        state.has_pending
      )
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'list_session',
          data: data.result,
        })
        return data
      })
  },
  changeStatusSession(_, payload) {
    const { groupId, status } = payload
    return this.$repository.v3.mentor.groupSessions
      .postChangeStatusSession(groupId, status)
      .then(({ data }) => {
        return data
      })
  },
  getGroupSessionById({ commit }, sessionId) {
    return this.$repository.v3.mentor.groupSessions
      .getGroupSessionById(sessionId)
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'group_session',
          data: data.result,
        })
        return data.result
      })
  },
  getAttendace({ commit }, weekId) {
    return this.$repository.v3.mentor.weeks.getAttendace(weekId).then((res) => {
      const { attendace, week } = res.data.result
      commit('SET_STATE_MENTORS', {
        type: 'attendace',
        data: attendace,
      })

      commit('SET_STATE_MENTORS', {
        type: 'week',
        data: week,
      })
      return attendace
    })
  },
  updateAttendace(_, payload) {
    const { id, content } = payload
    return this.$repository.v3.mentor.weeks
      .postUpdateAttendace(id, content)
      .then(({ data }) => {
        return data
      })
  },

  /* General Notes */
  getNotes({ commit }, groupSessionId) {
    return this.$repository.v3.mentor.groupSessions
      .getNotes(groupSessionId)
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'notes',
          data: data.result,
        })
        return data.result
      })
  },
  createNote({ dispatch }, payload) {
    const { groupSessionId, content } = payload
    return this.$repository.v3.mentor.groupSessions
      .postNote(groupSessionId, content)
      .then(async ({ data }) => {
        await dispatch('getNotes', groupSessionId)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ dispatch }, payload) {
    const { groupSessionId, idNote } = payload
    return this.$repository.v3.mentor.groupSessionNotes
      .deleteGroupSessionNote(idNote)
      .then(async ({ data }) => {
        await dispatch('getNotes', groupSessionId)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateNote({ dispatch }, payload) {
    const { groupSessionId, idNote, content } = payload
    return this.$repository.v3.mentor.groupSessionNotes
      .putGroupSessionNote(idNote, content)
      .then(async ({ data }) => {
        await dispatch('getNotes', groupSessionId)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  /* Postulation */
  getListPostulation({ commit }, payload) {
    const { groupSessionId, status } = payload
    const statusValue = status === 0 ? '' : `?status=${status}`
    return this.$repository.v3.mentor.groupSessions
      .getListPostulation(groupSessionId, statusValue)
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'list_postulation',
          data: data.result,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  /* Deliverables */
  getListDeliverables({ commit }, payload) {
    const { groupSessionId, status = '', selectCheckbox = '' } = payload
    return this.$repository.v3.mentor.groupSessions
      .getListDeliverables(groupSessionId, status, selectCheckbox)
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'list_deliverables',
          data: data.result,
        })
        return data
      })
  },

  /* Deliverables Panels */
  getDeliverableById({ commit }, userDeliverableId) {
    return this.$repository.v3.mentor.userDeliverables
      .getDeliverableById(userDeliverableId)
      .then(({ data }) => {
        commit('SET_STATE_MENTORS', {
          type: 'deliverable',
          data: data.result,
        })
        return data
      })
  },
  updateUrlDeliverable({ dispatch }, payload) {
    const { id, link } = payload
    return this.$repository.v3.mentor.userDeliverables
      .postUpdateUrlDeliverable(id, link)
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateUrlFeedback({ dispatch }, payload) {
    const { id, link = '', isReview, deliverableReviewId } = payload
    const params = {
      feedback_video: link,
      isReview,
      deliverableReviewId,
    }
    return this.$repository.v3.mentor.userDeliverables
      .postUpdateUrlFeedback(id, params)
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateCommentFeedback({ dispatch }, payload) {
    const { id, comment = '', isReview, deliverableReviewId } = payload
    const params = {
      comment,
      isReview,
      deliverableReviewId,
    }
    return this.$repository.v3.mentor.userDeliverables
      .postUpdateCommentFeedback(id, params)
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateUrlCorrected({ dispatch }, payload) {
    const { id, link = '' } = payload
    return this.$repository.v3.mentor.userDeliverables
      .postUpdateUrlCorrected(id, { submission_url_corrected: link })
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  approveDeliverable({ dispatch }, { id }) {
    return this.$repository.v3.mentor.userDeliverables
      .postApproveDeliverable(id)
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  revertDeliverable({ dispatch }, { id }) {
    return this.$repository.v3.mentor.userDeliverables
      .postRevertDeliverable(id)
      .then(async ({ data }) => {
        await dispatch('getDeliverableById', id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
