import { useRequestOffice } from '~/repositories'
const pathApplicant = '/api/v3/admission/applicant'

export default ($axios) => ({
  postUpdateAccessGO(applicantID, params) {
    return useRequestOffice(
      'post',
      `${pathApplicant}/${applicantID}/update-mode-access-deal`,
      params
    )
  },
  getNotes(applicantId) {
    return $axios.get(`${pathApplicant}/${applicantId}/customer-success-notes`)
  },
  getLogsApplicant(applicantId) {
    return $axios.get(`${pathApplicant}/${applicantId}/activity-logs`)
  },
})
