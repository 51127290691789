const resource = '/mentor/reports'

export default ($axios) => ({
  getStages(payload) {
    return $axios.get(`${resource}/general_data`, {
      params: payload,
    })
  },
  getUser() {
    return $axios.get(`${resource}/active_users`, {
      params: {},
    })
  },
  getProfileCompleted(payload) {
    return $axios.get(`${resource}/profile_completed`, {
      params: {
        weeks: payload,
      },
    })
  },
})
