export default ({ store, $gtm }) => ({
  // Trigger: Evento de login con GTM (mixpanel y compañía)
  sendLoginOk(userParams) {
    const user = store.state.auth.user

    // Si no esta logueado y tampoco parámetros de login, no hacer nada
    if (user === null && userParams == null) return

    let eventData = { ...userParams }

    // Si esta logueado remplazo la data del me
    if (user) {
      const userAuth = {
        $first_name: user.name,
        $last_name: `${user.paternal_lastname} ${user.maternal_lastname}`,
        $name: user.full_name,
        $email: user.email,
        email: user.email,
        area: user.area_name,
      }

      eventData = { ...eventData, ...userAuth }
    }

    $gtm.push({ event: 'login user', eventData })
  },
})
