const resource = 'api/v3/collection/user'
const request = 'request-remove-card-data'
const requests = 'requests-remove-card-data'
const takePossession = 'requests-remove-card-data-take-possession'

export default ($axios) => ({
  getRequestsByUserId(userId) {
    return $axios.get(`${resource}/${userId}/${requests}`)
  },
  postRemoveCard({ user, params }) {
    return $axios.post(`${resource}/${user}/${request}`, params)
  },
  postAssignPossession(userId) {
    return $axios.post(`${resource}/${takePossession}/${userId}`)
  },
  postCompleteRequestsRemoveCardData(requestRemoveCardDataId) {
    return $axios.post(
      `${resource}/${request}/${requestRemoveCardDataId}/complete`
    )
  },
})
