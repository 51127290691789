import { notification } from 'ant-design-vue'

const initialState = {
  requests: [],
  userRequests: [],
  requestsPendingCount: 0,
  statuses: [
    {
      id: 1,
      label: 'Pendientes',
      value: 'PENDING',
    },
    {
      id: 2,
      label: 'Completados',
      value: 'COMPLETED',
    },
  ],
}

export const state = () => ({ ...initialState })

export const getters = {
  pendingState: (state) => {
    return state.statuses[0]
  },
  completedState: (state) => {
    return state.statuses[1]
  },
  lastUserRequestCompleted: (state, getters) => {
    return state.userRequests
      .filter((request) => request.status === getters.completedState.value)
      .pop()
  },
  userRequestPending: (state, getters) => {
    return state.userRequests.find(
      (request) => getters.pendingState.value === request.status
    )
  },
  statusName: (state) => (status, mentor) => {
    if (!status) return '-'
    if (state.statuses.length === 0) return '-'
    if (status === 'PENDING' && mentor) return 'En gestión'
    const findState = state.statuses.find((row) => row.value === status)

    if (!findState) {
      return state.statuses[0].label
    }

    return findState.label
  },
}

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  getRequests({ commit }, params) {
    return this.$repository.v3.collection
      .getRequests(params)
      .then(({ data }) => {
        commit('SET_STATE', {
          type: 'requests',
          value: data.result.data,
        })
        return data
      })
  },
  getRequestsByUserId({ commit }, userId) {
    return this.$repository.v3.collection.user
      .getRequestsByUserId(userId)
      .then(({ data }) => {
        commit('SET_STATE', {
          type: 'userRequests',
          value: data.result,
        })
        return data
      })
  },
  getPendingRequestsTotal({ commit }) {
    return this.$repository.v3.collection
      .getRequestsTotal()
      .then(({ data }) => {
        commit('SET_STATE', {
          type: 'requestsPendingCount',
          value: data.result,
        })
        return data
      })
  },
  approveRequest({ dispatch }, requestId) {
    return this.$repository.v3.collection
      .postApproveRequestRemoveCard(requestId)
      .then(async (res) => {
        await dispatch('getPendingRequestsTotal')
        return res.data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  removeCard({ dispatch }, params) {
    return this.$repository.v3.collection.user
      .postRemoveCard(params)
      .then(async (res) => {
        await dispatch('getPendingRequestsTotal')
        return res.data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  assignPossession({ commit, state }, userId) {
    return this.$repository.v3.collection.user
      .postAssignPossession(userId)
      .then((res) => {
        commit('SET_STATE', {
          type: 'requestsPendingCount',
          value: state.requestsPendingCount - 1,
        })
        commit('SET_STATE', {
          type: 'userRequests',
          value: res.data.result,
        })
        return res.data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  postCompleteRequestsRemoveCardData({ commit }, requestRemoveCardDataId) {
    return this.$repository.v3.collection.user
      .postCompleteRequestsRemoveCardData(requestRemoveCardDataId)
      .then(({ data }) => {
        commit('SET_STATE', {
          type: 'userRequests',
          value: [data.result].flat(),
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  postUpdatePaymentsSchedule({ commit }, paymentData) {
    return this.$repository.v3.collection
      .postUpdatePaymentsSchedule(paymentData)
      .then(({ data }) => data)
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
