import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/mentor'

export default ($axios) => ({
  postTogglePlacement(talentId) {
    return useRequestOffice(
      'post',
      `${resource}/toggle-talent-placement-form`,
      {
        user_id: talentId,
      }
    )
  },
  postTogglePreTalenty(talentId) {
    return useRequestOffice('post', `${resource}/toggle-pretalently`, {
      user_id: talentId,
    })
  },
  postSuccessFeeOptions(talentId) {
    return useRequestOffice(
      'post',
      `${resource}/get-talent-success-fee-options`,
      {
        user_id: talentId,
      }
    )
  },
  // TODO FALTA REMPLAZAR
  getMentorsChangelog(userId) {
    return useRequestOffice(
      'get',
      `${resource}/user/${userId}/log-reassing-mentor`
    )
  },
  getDeliverablesByMentor() {
    return $axios.get(`${resource}/list-deliverables-mentor`, {})
  },
  getTalentProfile(talentId) {
    return useRequestOffice('get', `${resource}/user-info/${talentId}`)
  },
})
