import Cookies from 'js-cookie'

export default function ({ app }) {
  if (!app.mixins) {
    app.mixins = []
  }

  app.mixins.push({
    // Ciclo de vida de la instancía pincipal de vue
    mounted() {
      this.$tracking.user.sendLoginOk()
    },
    created() {
      const collapsedSidebar = Cookies.get('collapsedSidebar')

      if (collapsedSidebar === 'true') {
        this.$store.commit('SET_STATE', {
          type: 'collapsedSidebar',
          value: true,
        })
      }
    },
  })
}
