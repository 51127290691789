const getAttributes = (user) => {
  return {
    id: user.id,
    email: user.email,
  }
}

export default {
  getAttributes,
}
