const initialState = {
  leads: {},
  pagination: {},
  lead: {},
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_LEADS(state, { type, value }) {
    state[type] = value
  },
  SET_SALES_EXECUTIVE(state, { idLead, content }) {
    const positionInTable = state.leads.data.findIndex((e) => e.id === idLead)
    if (positionInTable !== -1) {
      state.leads.data[positionInTable].sales_executive = content
    }
  },
  SET_VALUE_ASSIGN_IN_LEAD(state, { type, value }) {
    state.lead[type] = { ...value }
  },
  SET_NUMBER_OF_VACANCIES_IN_LEAD(state, { value }) {
    state.lead.vacancy_qty = value
  },
  SET_BUSINESS_IN_LEAD(state, { object }) {
    state.lead.business = object
  },
  SET_NEW_OWNER_IN_LEAD(state, { object }) {
    state.lead.owner = object
  },
  SET_UTMS_IN_LEAD(state, { value }) {
    state.lead.utm_campaign = value.utm_campaign
    state.lead.utm_content = value.utm_content
    state.lead.utm_source = value.utm_source
    state.lead.utm_medium = value.utm_medium
    state.lead.utm_term = value.utm_term
  },
}

export const actions = {
  async fetchLeads({ commit }, params) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.getLeads(params)
    if (response.success) {
      const { paginate } = response.result
      commit('SET_STATE_LEADS', {
        type: 'leads',
        value: response.result,
      })
      commit('SET_STATE_LEADS', {
        type: 'pagination',
        value: {
          pageSize: paginate.per_page,
          current: paginate.current_page,
          total: paginate.total,
        },
      })
    }
    return response
  },
  async updateSalesExecutive(_, { idLead, body, content }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.patchSalesExecutive(
        idLead,
        body
      )
    return response
  },
  async createLead({ dispatch }, newLead) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.postCreateLead(
        newLead
      )
    if (response.success) {
      await dispatch('fetchLeads', {})
    }
    return response
  },
  async updateLead({ dispatch }, { idLead, body }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.putUpdateLead(
        idLead,
        body
      )
    if (response.success) {
      await dispatch('fetchLeadById', idLead)
    }
    return response
  },
  async fetchLeadById({ commit }, idLead) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.getLeadById(
        idLead
      )
    if (response.success) {
      commit('SET_STATE_LEADS', {
        type: 'lead',
        value: response.result,
      })
    }
    return response
  },
  async updateMeetings({ commit }, { idLead, body }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.postCreateMeeting(
        idLead,
        body
      )
    if (response.success) {
      commit('SET_VALUE_ASSIGN_IN_LEAD', {
        type: 'meeting',
        value: response.result,
      })
    }
    return response
  },
  async saveInterview({ commit }, { idLead, body, service }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.postCreateInterview(
        idLead,
        body
      )
    if (response.success) {
      commit('SET_VALUE_ASSIGN_IN_LEAD', {
        type: 'interview',
        value: { ...body, service },
      })
    }
    return response
  },
  async updateStatusLead(_, { idLead, newStatus, body }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.patchChangeStatus(
        idLead,
        newStatus,
        body
      )
    return response
  },
  async updateAssistance({ commit }, { idLead, body, meeting }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.patchUpdateAssistance(
        idLead,
        body
      )
    if (response.success) {
      commit('SET_VALUE_ASSIGN_IN_LEAD', {
        type: 'meeting',
        value: meeting,
      })
    }
    return response
  },
  async updateNumberOfVacancies({ commit }, { idLead, body }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.patchUpdateNumberOfVacancies(
        idLead,
        body
      )
    if (response.success) {
      commit('SET_NUMBER_OF_VACANCIES_IN_LEAD', {
        value: body.number_of_vacancies,
      })
    }
    return response
  },
  async updateOwner({ commit }, { idLead, newOwnerId }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.patchUpdateOwner(
        idLead,
        { owner_id: newOwnerId }
      )
    // if (response.success) {
    //   commit('SET_NEW_OWNER_IN_LEAD', {
    //     object: response.result.owner,
    //   })
    // }
    return response
  },
  async updateLeadUtms({ commit }, { idLead, body }) {
    const response =
      await this.$repository.v4.businessSales.businessProspects.putUpdateLeadUtms(
        idLead,
        body
      )

    if (response.success) {
      commit('SET_UTMS_IN_LEAD', {
        value: body,
      })
    }
    return response
  },
}
