
import { mapState } from 'vuex'

export default {
  name: 'UserMenu',
  data: () => ({
    showMenu: false,
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState(['collapsedSidebar']),
  },
}
