export const state = () => ({
  groupSessions: [],
  talentsWithoutGroup: [],
  listGroupSessions: [],
  groupSession: [],
  week: [],
  talents: [],
})

export const mutations = {
  SET_STATE_GROUP_SESSION(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getGroupSessions({ commit }, params) {
    const { result, success } =
      await this.$repository.learning.groupSession.getGroupSessions(params)
    if (success) {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'groupSessions',
        data: result,
      })
    } else {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'groupSessions',
        data: [],
      })
    }
    return result
  },
  async deleteGroupSession({ dispatch }, id) {
    const { result, success } =
      await this.$repository.learning.groupSession.deleteGroupSession(id)
    if (success) {
      await dispatch('getGroupSessions')
    }
    return result
  },
  async createGroupSession({ dispatch }, newGroupSession) {
    const { result, success } =
      await this.$repository.learning.groupSession.postGroupSession(
        newGroupSession
      )
    if (success) {
      await dispatch('getGroupSessions')
    }
    return result
  },
  async updateGroupSession({ dispatch }, { id, newGroupSession }) {
    const { result, success } =
      await this.$repository.learning.groupSession.putGroupSession(
        id,
        newGroupSession
      )
    if (success) {
      await dispatch('getGroupSessions')
    }
    return result
  },

  // TALENTS SIN GRUPO
  async getListTalentsWithoutGroup({ commit }, params) {
    const { result, success } =
      await this.$repository.learning.groupSession.getListTalentsWithoutGroup(
        params
      )
    if (success) {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'talentsWithoutGroup',
        data: result,
      })
    } else {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'talentsWithoutGroup',
        data: [],
      })
    }
    return result
  },
  async assignGroupSessionTalent({ dispatch }, { id, groupSession }) {
    const { result, success } =
      await this.$repository.learning.groupSession.putGroupSession(
        id,
        groupSession
      )
    if (success) {
      await dispatch('getListTalentsWithoutGroup')
    }
    return result
  },
  // DETALLE DE UNA SESIÓN GRUPAL
  async getGroupSessionsById({ commit }, id) {
    const { result, success } =
      await this.$repository.apiV3.getGroupSessionsById(id)
    if (success) {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'groupSession',
        data: result,
      })
    } else {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'groupSession',
        data: [],
      })
    }
    return result
  },
  // SEMANAS DE LA SESIÓN GRUPAL
  async getListWeek({ commit }, params) {
    const { result, success } = await this.$repository.apiV3.getListWeek(params)
    if (success) {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'week',
        data: result,
      })
    } else {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'week',
        data: [],
      })
    }
    return result
  },
  async deleteWeek({ dispatch }, id) {
    const { result, success } = await this.$repository.apiV3.deleteWeek(id)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
  async createWeek({ dispatch }, newWeek) {
    const { result, success } = await this.$repository.apiV3.postWeek(newWeek)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
  async updateWeek({ dispatch }, { id, newWeek }) {
    const { result, success } =
      await this.$repository.learning.groupSession.putWeek(id, newWeek)
    if (success) {
      await dispatch('getListWeek')
    }
    return result
  },
  // TALENTS DE LA SESIÓN GRUPAL
  async getListTalents({ commit }, params) {
    const { result, success } =
      await this.$repository.learning.groupSession.getListTalents(params)
    if (success) {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'talents',
        data: result,
      })
    } else {
      commit('SET_STATE_GROUP_SESSION', {
        type: 'talents',
        data: [],
      })
    }
    return result
  },
  async deleteTalent({ dispatch }, id) {
    const { result, success } =
      await this.$repository.learning.groupSession.deleteTalent(id)
    if (success) {
      await dispatch('getTalents')
    }
    return result
  },
  async createTalent({ dispatch }, newWeek) {
    const { result, success } =
      await this.$repository.learning.groupSession.postTalent(newWeek)
    if (success) {
      await dispatch('getTalents')
    }
    return result
  },
  async updateTalent({ dispatch }, { id, newWeek }) {
    const { result, success } =
      await this.$repository.learning.groupSession.putTalent(id, newWeek)
    if (success) {
      await dispatch('getTalents')
    }
    return result
  },
}
