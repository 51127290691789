import { notification } from 'ant-design-vue'

const initialState = {
  leads: [],
  apollo_users: [],
  status: [],
  counter_by_status: [],
  lead: null,
  notes: [],
}

export const state = () => ({ ...initialState })

export const getters = {
  leadStatusName: (state) => (id) => {
    if (!id) return '-'
    if (state.status.length === 0) return id
    const findState = state.status.find((status) => status.id === id)
    if (!findState) {
      return state.status[0].name
    }
    return findState.name
  },
}

export const mutations = {
  SET_STATE(state, { type, value }) {
    state[type] = value
  },
  ADD_STATE_LEAD(state, { value }) {
    state.leads.unshift(value)
  },
  ADD_NOTE(state, { value }) {
    state.notes.unshift(value)
  },
  UPDATE_NOTE(state, payload) {
    const indexUpdate = state.notes.findIndex((item) => item.id === payload.id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1, payload)
    }
  },
  DELETE_NOTE(state, id) {
    const indexUpdate = state.notes.findIndex((item) => item.id === id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1)
    }
  },
}

export const actions = {
  getLeads({ commit }, params) {
    return this.$repository.v3.leads
      .postGetLeads(params)
      .then((res) => {
        commit('SET_STATE', {
          type: 'leads',
          value: res.data.result.leads.data,
        })
        commit('SET_STATE', {
          type: 'counter_by_status',
          value: res.data.result.prospectCounter,
        })
        return res.data.result.leads
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getUsersApollo({ commit }) {
    return this.$repository.v3.leads
      .getUsersApollo()
      .then((res) => {
        commit('SET_STATE', {
          type: 'apollo_users',
          value: res.data.data.users,
        })
        return res.data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createLead({ commit }, params) {
    return this.$repository.v3.leads
      .postCreateLead(params)
      .then((res) => {
        commit('ADD_STATE_LEAD', {
          value: res.data.result.data,
        })
        return {
          status: res.status,
          data: res.data.success,
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.data.message,
        })
        return err
      })
  },
  changeStatusLead(_, params) {
    return this.$repository.v3.leads
      .postChangeStatusLead(params)
      .then((res) => {
        return res
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  async fetchLeadFilters({ state, commit }) {
    if (state.status.length !== 0) return state.status
    const response = await this.$repository.v3.leads.getLeadFilters()
    if (response.success) {
      commit('SET_STATE', {
        type: 'status',
        value: response.status,
      })
    }
    return response
  },
  getLead({ commit }, params) {
    commit('SET_STATE', {
      type: 'lead',
      value: params,
    })
  },

  // Notes
  getNotes({ commit }, leadId) {
    commit('SET_STATE', {
      type: 'notes',
      value: [],
    })
    return this.$repository.v3.leads
      .getNotes(leadId)
      .then((res) => {
        commit('SET_STATE', {
          type: 'notes',
          value: res.data.result,
        })
        return res.data.result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createNote({ commit }, params) {
    return this.$repository.v3.leads
      .postCreateNote(params)
      .then((res) => {
        commit('ADD_NOTE', {
          value: res.data.result.note,
        })
        return {
          status: res.status,
          data: res.data.success,
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.data.message,
        })
        return err
      })
  },
  updateNote({ commit }, payload) {
    return this.$repository.v3.leads
      .postUpdateNote(payload)
      .then(({ data }) => {
        commit('UPDATE_NOTE', data.result.note)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ commit }, payload) {
    return this.$repository.v3.leads
      .postDeleteNote(payload)
      .then(({ data }) => {
        commit('DELETE_NOTE', payload.note_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
