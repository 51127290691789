export const state = () => ({
  week: [],
})

export const mutations = {
  SET_STATE_WEEK(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getSessions({ commit }) {
    const response = await this.$repository.v3.mentor.weeks.getSessions()
    if (response.success) {
      commit('SET_STATE_WEEK', {
        type: 'week',
        data: response.result,
      })
    }

    return response
  },
  async deleteSession({ dispatch }, id) {
    const response = await this.$repository.v3.mentor.weeks.deleteSession(id)
    if (response.success) {
      await dispatch('getSessions')
    }
    return response
  },
  async createSession({ dispatch }, newSession) {
    const response = await this.$repository.v3.mentor.weeks.postSession(
      newSession
    )
    if (response.success) {
      await dispatch('getSessions')
    }
    return response
  },
  async updateSession({ dispatch }, { id, newSession }) {
    const response = await this.$repository.v3.mentor.weeks.putSession(
      id,
      newSession
    )
    if (response.success) {
      await dispatch('getSessions')
    }

    return response
  },
}
