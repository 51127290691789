import { useRequestOffice } from '~/repositories'
const admissionLead = '/api/v3/lead'

export default ($axios) => ({
  postGetLeads(params) {
    return $axios.post(`${admissionLead}/leads`, params)
  },
  getUsersApollo() {
    return $axios.get(`${admissionLead}/users-apollo`)
  },
  postCreateLead(params) {
    return $axios.post(`${admissionLead}/store-lead`, params)
  },
  postChangeStatusLead(params) {
    return $axios.post(`${admissionLead}/change-status`, params)
  },
  getLeadFilters() {
    return useRequestOffice('get', `${admissionLead}/filter-list`)
  },

  // Notes
  getNotes(leadId) {
    return $axios.get(`${admissionLead}/notes/${leadId}`)
  },
  postCreateNote(params) {
    return $axios.post(`${admissionLead}/notes`, params)
  },
  postUpdateNote(params) {
    return $axios.post(`${admissionLead}/edit_notes`, params)
  },
  postDeleteNote(params) {
    return $axios.post(`${admissionLead}/delete_notes`, params)
  },
})
