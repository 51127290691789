const initialState = {
  status_tags: [],
  touch_points: [],
  user_touchpoint_status: {},
  loading_tags: true,
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_TAG(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async getProfileTag({ commit }, userId) {
    commit('SET_STATE_TAG', {
      type: 'loading_tags',
      value: true,
    })

    const dataContent =
      await this.$repository.mentor.userProfile.profileComplete(userId)

    if (dataContent.success) {
      commit('SET_STATE_TAG', {
        type: 'status_tags',
        value: dataContent.data.profile.status_tags,
      })
      commit('SET_STATE_TAG', {
        type: 'user_touchpoint_status',
        value: dataContent.data.user_touchpoint_status,
      })
    }

    commit('SET_STATE_TAG', {
      type: 'loading_tags',
      value: false,
    })

    return dataContent
  },
  async deleteTag({ dispatch }, content) {
    const dataContent =
      await this.$repository.v3.customerSuccessV3.postDeleteTag(content)
    if (dataContent.success) {
      await dispatch('getProfileTag', { user_id: content.user_id })
    }
    return dataContent
  },
  async registerReason({ dispatch }, content) {
    const dataContent =
      await this.$repository.v3.customerSuccessV3.postRegisterReason(content)
    if (dataContent.success) {
      await dispatch('getProfileTag', { user_id: content.user_id })
    }
    return dataContent
  },
  async addTag({ dispatch }, content) {
    const dataContent = await this.$repository.v3.customerSuccessV3.postAddTag(
      content
    )
    if (dataContent.success) {
      await dispatch('getProfileTag', { user_id: content.user_id })
    }
    return dataContent
  },
  async getTouchpoints({ commit }, userId) {
    const dataContent =
      await this.$repository.v3.customerSuccessV3.getTouchpoints(userId)
    if (dataContent.success) {
      commit('SET_STATE_TAG', {
        type: 'touch_points',
        value: dataContent.result.categories,
      })
    }
    return dataContent
  },
}
