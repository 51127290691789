const resource = 'api/v3/collection/contract'

export default ($axios) => ({
  // CONTRACT
  postApproveContract(id) {
    return $axios.post(`${resource}/${id}/approve`)
  },
  postRejectContract(id) {
    return $axios.post(`${resource}/${id}/reject`)
  },
  postSaveContractManually(id, params) {
    return $axios.post(`${resource}/${id}/manual_mode`, params)
  },
  getActiveProcesses(id) {
    return $axios.get(`${resource}/${id}/active_process`)
  },
  getTalentSignedContractURL(id) {
    return $axios.get(`${resource}/${id}/signed-contract`)
  },
})
