import moment from 'moment'
import { notification } from 'ant-design-vue'
import DeviceDetector from 'device-detector-js'

const _ = require('lodash')

const initialState = {
  applicant: null,
  applicants: [],
  paymentResources: null,
  applicantFormData: null,
  rejectionCategories: null,

  fullPayments: [],
  utms: [],

  activeProcess: [],
  interviewData: null,
  paymentQuotas: [],
}

export const state = () => ({ ...initialState })

export const getters = {
  ownerName: (state, getters, rootState) => (id) => {
    if (!id) return '-'
    const member = rootState.support.resources.admission_members.find(
      (member) => member.id === id
    )
    return member.full_name || '-'
  },
  startDate: (state) => (format) => {
    if (!state.applicant) return null
    if (state.applicant.user && state.applicant.user.activated_at) {
      if (format) {
        return moment(state.applicant.user.activated_at).format(format)
      }

      return state.applicant.user.activated_at
    }
    if (state.applicant.dead_line) {
      if (format) {
        return moment(state.applicant.dead_line).format(format)
      }

      return state.applicant.dead_line
    }
    return null
  },
  ownerFullName: (state, getters, rootState) => (id) => {
    if (!id) return '-'
    const member = rootState.support.resources.admission_members.find(
      (member) => member.id === id
    )
    return member.full_name || '-'
  },
  statusName: (state, getters, rootState) => (id) => {
    if (!id) return '-'
    const findState = rootState.support.resources.applicant_statuses.find(
      (status) => status.id === id
    )
    return findState.name || '-'
  },
  getCreatedAt: () => (val) => {
    if (!val) return null
    return moment(val).format('DD/MM/YYYY HH:mm:ss')
  },
  utms: (state) => {
    if (state.utms.length > 0) {
      return _.orderBy(state.utms, ['createdAt'], ['desc'])
    }
    return state.utms
  },
  getDevice: () => (val) => {
    if (!val) return null
    const deviceDetector = new DeviceDetector()
    return deviceDetector.parse(val)
  },
  getQuotas: (state) => (val) => {
    if (!val) return null
    const findQuotas = state.paymentQuotas.find((item) => item.id === val)
    if (findQuotas) {
      return findQuotas
    }
    return null
  },
}

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_APPLICANTS(state, { type, value }) {
    state[type] = value
  },
  UPDATE_APPLICANT(state, payload) {
    state.applicant = { ...state.applicant, ...payload }
  },
  UPDATE_KEY_APPLICANT(state, { key, value }) {
    state.applicant[key] = value
  },
  SET_STATE_ACTIVE_PROCESSES(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchApplicantFormData({ state, commit }) {
    if (state.applicantFormData) return state.applicantFormData
    const response = await this.$repository.admissions.getApplicantFormData()

    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'applicantFormData',
        value: response.data,
      })
    }
    return response
  },
  async getApplicants({ commit }, params) {
    const response =
      await this.$repository.mentor.applicants.getApplicantsFilter(params)
    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'applicants',
        value: response.data.data,
      })
    }
    return response
  },
  async fetchApplicant({ state, commit }, id) {
    const response = await this.$repository.mentor.applicants.getApplicant(id)
    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'applicant',
        value: response.data,
      })

      if (![1, 2, 8, 9].includes(response.data.applicant_status_id))
        return response

      if (state.rejectionCategories) return state.rejectionCategories

      const resp =
        await this.$repository.v3.admission.applicants.rejectionCategories.getCategories()

      if (resp.success) {
        commit('SET_STATE_APPLICANTS', {
          type: 'rejectionCategories',
          value: resp.result,
        })
      }
    }
    return response
  },
  async updateApplicant({ commit }, payload) {
    const response = await this.$repository.mentor.applicants.postApplicant(
      payload
    )

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'applicant',
        value: response.data,
      })
    }

    return response
  },
  updateActivatedAt({ state, dispatch }, payload) {
    return this.$repository.mentor.userProfile
      .postUpdateActivatedAt(payload)
      .then(async ({ data }) => {
        await dispatch('fetchApplicant', state.applicant.id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  // APPLICANT
  async fetchPaymentResources({ state, commit }, payload) {
    if (state.paymentResources) return state.paymentResources

    const response =
      await this.$repository.mentor.applicants.getPaymentResources(payload)
    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'paymentResources',
        value: response.data,
      })
    }
    return response
  },
  async saveApplicantData({ state, dispatch }, payload) {
    const response = await this.$repository.mentor.applicants.postApplicantData(
      payload
    )

    if (!response.success) {
      const data = response.data
      const message =
        response.status === 422
          ? 'Por favor, verifique los datos del formulario'
          : data.message
      notification.error({
        message: 'Error',
        description: message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async updatePlaceabiltyIindex({ state, dispatch }, payload) {
    const response =
      await this.$repository.mentor.applicants.postPlaceabiltyIindex(payload)

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async updateAccessGO({ state, dispatch }, params) {
    const response =
      await this.$repository.v3.admission.applicant.postUpdateAccessGO(
        state.applicant.id,
        params
      )

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  updateApplicantInterviewData({ state, dispatch }, payload) {
    return this.$repository.v3.admission.applicants
      .postUpdateApplicantInterviewData(payload)
      .then(async (data) => {
        await dispatch('getInterviewDataApplicant', {
          applicant_id: state.applicant.id,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  // Set status
  async startInterview({ state, dispatch }, id) {
    const response = await this.$repository.mentor.applicants.postInterview({
      applicant_id: id,
    })

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async admitApplicant({ state, dispatch }, id) {
    const response =
      await this.$repository.mentor.applicants.postAdmitApplicant({
        applicant_id: id,
      })

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async setApplicantPayment({ state, dispatch }, payload) {
    const response =
      await this.$repository.mentor.applicants.postApplicantPayment(payload)

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async interviewNotDone({ state, dispatch }, id) {
    const response =
      await this.$repository.mentor.applicants.postInterviewNotDone({
        applicant_id: id,
      })

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      await dispatch('fetchApplicant', state.applicant.id)
    }

    return response
  },
  async rejectApplicant({ commit }, payload) {
    const response =
      await this.$repository.mentor.applicants.postRejectApplicant(payload)

    if (!response.success) {
      notification.error({
        message: 'Error',
        description: response.message,
      })
    }

    if (response.success) {
      commit('UPDATE_APPLICANT', {
        applicant_status_id: 6,
      })
      commit('UPDATE_APPLICANT', {
        rejection_note: response.data,
      })
    }

    return response
  },
  rejectPreScreeningApplicant({ commit }, payload) {
    return this.$repository.v3.admission.applicants
      .postRejectPreScreeningApplicant(payload)
      .then(({ data }) => {
        commit('UPDATE_APPLICANT', {
          applicant_status_id: 6,
        })
        commit('UPDATE_APPLICANT', {
          rejection_note: data.result,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  // PAYMENTS
  getFullPayments({ commit }, params) {
    return this.$repository.mentor.applicants
      .postGetFullPayments(params)
      .then(({ data }) => {
        commit('SET_STATE_APPLICANTS', {
          type: 'fullPayments',
          value: data.data.data,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  async fetchApplicantUtmLogs({ commit }, applicantId) {
    const response =
      await this.$repository.v3.admission.applicants.getApplicantUtmLogs(
        applicantId
      )

    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'utms',
        value: response.result,
      })
    }

    return response
  },

  // Contact Later
  setApplicantContactLater({ state, dispatch }, params) {
    return this.$repository.v3.admission.applicants
      .postContactLater({
        applicant_id: params.applicant_id,
        date_contact_later: params.date_contact_later,
      })
      .then(async ({ data }) => {
        await dispatch('fetchApplicant', state.applicant.id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  async getActiveProcess({ commit }, id) {
    const response =
      await this.$repository.v3.admission.applicants.activeProcesses.getActiveProcessesApplicant(
        id
      )

    if (response.success) {
      commit('SET_STATE_ACTIVE_PROCESSES', {
        type: 'activeProcess',
        value: response.result,
      })
    }
    return response
  },
  async storeActiveProcess(_, payload) {
    return await this.$repository.v3.admission.applicants.activeProcesses.postStoreActiveProcessesApplicant(
      payload
    )
  },
  async getInterviewDataApplicant({ commit }, payload) {
    const response =
      await this.$repository.mentor.applicants.postGetInterviewDataApplicant(
        payload
      )
    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'interviewData',
        value: response.result,
      })
    }
    return response
  },

  async getPaymentQuotas({ commit }) {
    const response = await this.$repository.mentor.applicants.getPaymentQuotas()
    if (response.success) {
      commit('SET_STATE_APPLICANTS', {
        type: 'paymentQuotas',
        value: response.result,
      })
    }
    return response
  },
}
