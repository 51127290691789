export const keySupportStorage = 'support_resources'

const initialState = {
  cities: [],
  timeZones: [],
  resources: {
    payment_channels: [
      {
        id: 1,
        name: 'Stripe',
      },
      {
        id: 2,
        name: 'PayPal',
      },
      {
        id: 3,
        name: 'Mercado Pago',
      },
      {
        id: 4,
        name: 'Transferencia Bancaria',
      },
      {
        id: 5,
        name: 'Cryptos',
      },
      {
        id: 7,
        name: 'Payoneer',
      },
      {
        id: 6,
        name: 'Otros',
      },
    ],
    followup_statuses: [
      {
        id: '1',
        name: 'Por contactar',
      },
      {
        id: '2',
        name: 'Contactado',
      },
      {
        id: '3',
        name: 'Contact later',
      },
    ],
    contract_status: [
      { id: 1, name: 'Pendiente' },
      { id: 2, name: 'Aprobado' },
      { id: 3, name: 'Firmado' },
      { id: 4, name: 'Desaprobado' },
      { id: 5, name: 'No Aplica' },
      { id: 6, name: 'Vencido' },
    ],
    placement_status: [
      { id: 'pending', name: 'No enviado' },
      { id: 'completed', name: 'Por revisar' },
      { id: 'approved', name: 'Revisado' },
    ],
    prospect_lead_statuses: [
      {
        id: 'INTERESTED',
        name: 'Interesado',
      },
      {
        id: 'LOST',
        name: 'Perdido',
      },
      {
        id: 'POSTULATE',
        name: 'Postuló',
      },
    ],
  },
}

export const state = () => ({ ...initialState })

export const getters = {
  getResource: (state) => (type) => {
    if (!state.resources[type]) return []
    return state.resources[type]
  },
  statusName: (state) => (id) => {
    if (!id) return '-'
    if (state.resources.user_statuses.length === 0) return '-'

    const findState = state.resources.user_statuses.find(
      (status) => status.id === id
    )

    if (!findState) {
      return state.resources.user_statuses[0].name
    }

    return findState.label
  },
  leadStatusName: (state) => (id) => {
    if (!id) return '-'
    if (state.resources.prospect_lead_statuses.length === 0) return '-'

    const findState = state.resources.prospect_lead_statuses.find(
      (status) => status.id === id
    )

    if (!findState) {
      return state.resources.prospect_lead_statuses[0].name
    }

    return findState.name
  },
  agreementName: (state) => (id) => {
    if (!id) return '-'
    if (state.resources.contract_status.length === 0) return '-'

    const findState = state.resources.contract_status.find(
      (status) => status.id === id
    )

    if (!findState) {
      return state.resources.contract_status[0].name
    }

    return findState.name
  },
  ownerName: (state) => (id) => {
    if (!id) return '-'
    const member = state.resources.admission_members.find(
      (member) => member.id === id
    )
    return member ? member.name : '?'
  },
  initiativeName: (state) => (id) => {
    if (!id) return '-'
    const initiative = state.resources.applicant_initiatives.find(
      (initiative) => initiative.id === id
    )
    return initiative.name || '--'
  },
  scholarshipName: (state) => (id) => {
    if (!id) return '-'
    const scholarship = state.resources.scholarship_types.find(
      (scholarship) => scholarship.id === id
    )
    return scholarship.name || '--'
  },
  linkActiveCampaign: () => (id) => {
    return `${process.env.HOST_ACTIVE_CAMPAIGN}/${id}`
  },
  placementName: (state) => (id) => {
    if (!id) return '-'
    if (state.resources.placement_status.length === 0) return '-'

    const findState = state.resources.placement_status.find(
      (place) => place.id === id
    )

    if (!findState) {
      return state.resources.placement_status[0].name
    }

    return findState.name
  },
  getSkillsEverest: (state) => {
    if (state.resources && !state.resources.everest_developer_types) return []
    return state.resources.everest_developer_types
      .map((stack) => stack.skills)
      .reduce((a, b) => a.concat(b), [])
  },
}

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_SUPPORT(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchSupport({ state, commit }, keys = []) {
    const support = localStorage.getItem(keySupportStorage)

    if (support) {
      const listResources = { ...state.resources, ...JSON.parse(support) }

      commit('SET_STATE_SUPPORT', {
        type: 'resources',
        value: listResources,
      })
    }

    // Verifico que no existan las llaves en el store
    const storeKeys = Object.keys(state.resources)
    const newKeys = keys.filter((key) => !storeKeys.includes(key))

    // Si las keys es igual a 0, actualizo el store en segundo plano de esas keys
    if (newKeys.length === 0) {
      this.$repository.v3.support
        .getSupportResources({
          keys,
        })
        .then(({ result, success }) => {
          if (success) {
            const listResources = { ...state.resources, ...result }

            try {
              localStorage.setItem(
                keySupportStorage,
                JSON.stringify(listResources)
              )
            } catch (e) {
              console.log('Error to setItem in  localStorage: ', e)
            }

            commit('SET_STATE_SUPPORT', {
              type: 'resources',
              value: listResources,
            })
          }
        })

      return { success: true }
    }

    const response = await this.$repository.v3.support.getSupportResources({
      keys: newKeys,
    })

    if (response.success) {
      const newResources = { ...state.resources, ...response.result }
      try {
        localStorage.setItem(keySupportStorage, JSON.stringify(newResources))
      } catch (e) {
        console.log('Error to setItem in  localStorage: ', e)
      }

      commit('SET_STATE_SUPPORT', {
        type: 'resources',
        value: newResources,
      })
    }

    return response
  },
  async getTimezone({ state, commit }) {
    if (state.timeZones.length > 0) return state.timeZones
    const response = await this.$repository.v2.support.getTimeZones()

    if (response.success) {
      commit('SET_STATE_SUPPORT', {
        type: 'timeZones',
        value: response.data,
      })
    }

    return response
  },
  async fetchCities({ state, commit }, params) {
    const response = await this.$repository.v3.support.getCities(params)

    if (response.success) {
      commit('SET_STATE_SUPPORT', {
        type: 'cities',
        value: response.result,
      })
    }

    return response
  },
  async fetchFilterEvents({ state, commit }) {
    if (state.resources.calendars && state.resources.recurrence_rules) {
      return
    }

    const response =
      await this.$repository.v3.admin.calendarSession.getFilterEvents()

    if (response.success) {
      const newResources = { ...state.resources, ...response.result }

      commit('SET_STATE_SUPPORT', {
        type: 'resources',
        value: newResources,
      })
    }

    return response
  },
  async getModules({ state, commit }) {
    if (state.resources.courses) return

    const response = await this.$repository.v3.admin.modules.getModules()

    if (response.success) {
      const newResources = { ...state.resources, courses: response.result }

      commit('SET_STATE_SUPPORT', {
        type: 'resources',
        value: newResources,
      })
    }

    return response
  },
}
