import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v4/sales/coupons'

export default () => ({
  getCoupons(params) {
    const queryString = new URLSearchParams(params).toString()
    return useRequestOffice('get', `${rootPath}?${queryString}`)
  },

  getCoupon(couponId) {
    return useRequestOffice('get', `${rootPath}/${couponId}`)
  },

  getPromotionCodes(couponId, params) {
    const queryString = new URLSearchParams(params).toString()
    return useRequestOffice(
      'get',
      `${rootPath}/${couponId}/promotion-codes?${queryString}`
    )
  },

  storePromotionCode(couponId, params) {
    return useRequestOffice(
      'post',
      `${rootPath}/${couponId}/promotion-codes`,
      params
    )
  },

  storeCoupon(params) {
    return useRequestOffice('post', `${rootPath}`, params)
  },
})
