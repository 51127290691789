export const customColumns = (table) => {
  const questions = []

  const ignoreList = [
    'first_name',
    'name',
    'last_name',
    'linkedin',
    'phone',
    'email',
  ]

  const orderQuestion = [...table]
    .sort(
      (a, b) => a.questions_and_answers.length - b.questions_and_answers.length
    )
    .reverse()

  orderQuestion[0].questions_and_answers.forEach((item, key) => {
    if (!ignoreList.includes(item.question_ref)) {
      let titleCol = item.question
      let widthCol = 380

      if (item.question_ref === 'developer_type') {
        widthCol = 200
        titleCol = 'Perfil'
      }

      if (item.question_ref === 'experience_years') {
        titleCol = 'Años de Experiencia'
        widthCol = 110
      }

      if (item.question_ref === 'skills') {
        titleCol = 'Skills'
        widthCol = 250
      }

      if (item.question.includes('portafolio o Github')) {
        titleCol = 'Portafolio o Github'
        widthCol = 180
      }

      if (item.question.includes('nivel de inglés')) {
        titleCol = 'Nivel de inglés'
        widthCol = 180
      }

      if (item.question.includes('rango salarial mensual')) {
        titleCol = 'Salario mensual'
        widthCol = 200
      }

      if (item.question.includes('pagar tu matrícula')) {
        titleCol = 'Matrícula'
      }

      if (
        item.question.includes('¿dispones del tiempo y tienes el compromiso')
      ) {
        titleCol = 'Tiempo y compromiso'
        widthCol = 180
      }

      if (item.question.includes('Refiérenos')) {
        titleCol = 'Referencía'
      }

      questions.push({
        width: widthCol,
        title: titleCol,
        id: item.question_id,
        ref: item.question_ref,
        key,
      })
    }
  })

  return questions
}
