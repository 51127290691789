import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v3/partnership/match-users'

export default () => ({
  getNotes(idUser) {
    return useRequestOffice('get', `${rootPath}/${idUser}/interviews/notes/`)
  },
  postCreateNote(idUser, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idUser}/interviews/notes/`,
      body
    )
  },
  putUpdateNote(idUser, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/interviews/notes/${idUser}`,
      body
    )
  },
  deleteNote(idUser) {
    return useRequestOffice('delete', `${rootPath}/interviews/notes/${idUser}`)
  },
})
