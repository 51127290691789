import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/customer-success'

export default ($axios) => ({
  postDeleteTag(content) {
    return useRequestOffice(
      'post',
      `${resource}/remove-user-status-tag`,
      content
    )
  },
  postRegisterReason(content) {
    return useRequestOffice(
      'post',
      `${resource}/register-user-exit-reason`,
      content
    )
  },
  postAddTag(content) {
    return useRequestOffice('post', `${resource}/add-user-status-tag`, content)
  },
  getTouchpoints(userId) {
    return useRequestOffice(
      'get',
      `${resource}/user-status/${userId}/touchpoints`
    )
  },
  getTalents(params) {
    return $axios.get(`${resource}/talents`, { params })
  },
  postRevertPlacement(params) {
    return $axios.post(`${resource}/reset/placement`, {
      ...params,
    })
  },
})
