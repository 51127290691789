import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/admin/missions'

export default () => ({
  getListWeek(params) {
    return useRequestOffice('get', `${resource}`, { params })
  },
  deleteWeek(id) {
    return useRequestOffice('delete', `${resource}/${id}`)
  },
  postWeek(newWeek) {
    return useRequestOffice('post', `${resource}`, newWeek)
  },
  putWeek(id, newWeek) {
    return useRequestOffice('put', `${resource}/${id}`, newWeek)
  },
})
