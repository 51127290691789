const initialState = {
  listFilter: [],
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET(state) {
    const newState = initialState
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  SET_STATE_FILTER(state, { type, value }) {
    state[type] = value
  },
  SET_STATUS_TALENTS(state, payload) {
    const status = this.state.support.resources.user_statuses
    const { userID, userStatusId } = payload

    const findStatus = status.find((item) => item.id === userStatusId)
    const indexTalents = state.listFilter.findIndex(
      (item) => item.user_id === userID
    )

    if (findStatus && indexTalents >= 0) {
      state.listFilter[indexTalents].user_status_id = findStatus.id
    }
  },
  SET_CONTRACT_TALENTS(state, payload) {
    const { userID, contract } = payload

    const indexTalents = state.listFilter.findIndex(
      (item) => item.user_id === userID
    )

    if (indexTalents >= 0) {
      state.listFilter[indexTalents].contract_status = contract
    }
  },
}

export const actions = {
  getApplicants({ commit }, params) {
    return this.$repository.mentor.applicants
      .getApplicantsFilterCollection(params)
      .then(({ data }) => {
        commit('SET_STATE_FILTER', {
          type: 'listFilter',
          value: data.data.data,
        })
        return data
      })
  },
}
