import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v4/match/'

export default () => ({
  getSkills(params) {
    const queryString = new URLSearchParams(params).toString()
    return useRequestOffice('get', `${rootPath}skills?${queryString}`)
  },
  getSkillsByDeveloperType(developerTypeId, params) {
    const queryString = new URLSearchParams(params).toString()
    return useRequestOffice(
      'get',
      `${rootPath}roles/${developerTypeId}/skills?${queryString}`
    )
  },
  storeSkill(params) {
    return useRequestOffice('post', `${rootPath}skills`, params)
  },
  showSkill(skillId) {
    return useRequestOffice('get', `${rootPath}skills/${skillId}`)
  },
  updateSkill(skillId, params) {
    return useRequestOffice('put', `${rootPath}skills/${skillId}`, params)
  },
})
