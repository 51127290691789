import { useRequestOffice } from '~/repositories'
const admission = '/api/admissions'

export default () => ({
  getApplicantFormData() {
    return useRequestOffice('get', `${admission}/register_form_data`)
  },
  postImportApplicants(params) {
    return useRequestOffice(
      'post',
      `${admission}/import/scholarship-applicants`,
      params
    )
  },
})
