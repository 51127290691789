import { useRequestOffice } from '~/repositories'
const resouce = '/mentor/v2/support'

export default ($axios) => ({
  getStatus() {
    return $axios.get(`${resouce}/users/statuses`)
  },
  getTotalLeads() {
    return $axios.get(`${resouce}/total-leads`)
  },
  getTotalPlacementCompleted() {
    return useRequestOffice('get', `${resouce}/total-placement-completed`)
  },
})
