import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v3/partnership/businesses'

export default () => ({
  /* Empresas */
  getBusinesses(params) {
    return useRequestOffice('get', `${rootPath}`, { params })
  },
  showBusinesses(id) {
    return useRequestOffice('get', `${rootPath}/${id}`)
  },
  putBusinesses(id, body) {
    return useRequestOffice('put', `${rootPath}/${id}`, body)
  },
  postBusinesses(body) {
    return useRequestOffice('post', `${rootPath}`, body)
  },
  postBusinessForProspect(idLead, body) {
    return useRequestOffice('post', `${rootPath}/${idLead}`, body)
  },
  patchChangeStatusInactive(id) {
    return useRequestOffice('patch', `${rootPath}/${id}/change-status/inactive`)
  },
  patchChangeStatusActive(id) {
    return useRequestOffice('patch', `${rootPath}/${id}/change-status/active`)
  },
  getAvailableBusinessServices(id) {
    return useRequestOffice(
      'get',
      `${rootPath}/${id}/business-services/available`
    )
  },
})
