import { useRequestOffice } from '~/repositories'
const path = `/mentor/auth`

export default () => ({
  postLogin(params) {
    return useRequestOffice('post', `${path}/login`, params)
  },
  getMe() {
    return useRequestOffice('get', `${path}/me`)
  },
})
