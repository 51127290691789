import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v4/partnership/match-users'

export default () => ({
  getNotes(idUser) {
    return useRequestOffice('get', `${rootPath}/${idUser}/interviews/notes/`)
  },
  getNotesHire(idUser, idWorkOffer) {
    return useRequestOffice(
      'get',
      `${rootPath}/${idUser}/interviews/${idWorkOffer}/notes/`
    )
  },
  postCreateNote(idUser, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idUser}/interviews/notes/`,
      body
    )
  },
  postCreateNoteHire(idUser, idWorkOffer, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idUser}/interviews/${idWorkOffer}/notes/`,
      body
    )
  },
  putUpdateNote(idUser, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/interviews/notes/${idUser}`,
      body
    )
  },
  putUpdateNoteHire(idUser, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/interviews/business-notes/${idUser}`,
      body
    )
  },
  deleteNote(idUser) {
    return useRequestOffice('delete', `${rootPath}/interviews/notes/${idUser}`)
  },
  deleteNoteHire(idUser) {
    return useRequestOffice(
      'delete',
      `${rootPath}/interviews/business-notes/${idUser}`
    )
  },
})
