export const state = () => ({
  deliverables: [],
})

export const mutations = {
  SET_STATE_DELIVERABLE(state, { type, data }) {
    state[type] = data
  },
}

export const actions = {
  async getDeliverables({ commit }, params) {
    const { result, success } = await this.$repository.apiV3.getDeliverables(
      params
    )
    if (success) {
      commit('SET_STATE_DELIVERABLE', {
        type: 'deliverables',
        data: result,
      })
    } else {
      commit('SET_STATE_DELIVERABLE', {
        type: 'deliverables',
        data: [],
      })
    }
    return result
  },
  async deleteDeliverable({ dispatch }, id) {
    const { result, success } = await this.$repository.apiV3.deleteDeliverable(
      id
    )
    if (success) {
      await dispatch('getDeliverables')
    }
    return result
  },
  async createDeliverable({ dispatch }, newDeliverable) {
    const { result, success } = await this.$repository.apiV3.postDeliverable(
      newDeliverable
    )
    if (success) {
      await dispatch('getDeliverables')
    }
    return result
  },
  async updateDeliverable({ dispatch }, { id, newDeliverable }) {
    const { result, success } = await this.$repository.apiV3.putDeliverable(
      id,
      newDeliverable
    )
    if (success) {
      await dispatch('getDeliverables')
    }
    return result
  },
}
