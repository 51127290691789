import { useRequestOffice } from '~/repositories'
const resource = 'api/v3/interesting-leads'

export default ($axios) => ({
  getLeads(params) {
    return useRequestOffice('get', `${resource}`, { params })
  },
  getTotalInterestingLeads() {
    return useRequestOffice('get', `/${resource}/total-interesting`)
  },
  getStatus(params) {
    return useRequestOffice('get', `${resource}/status`, { params })
  },
  postChangeStatus(params) {
    return useRequestOffice('post', `${resource}/change-status`, params)
  },
  getNotes(leadId) {
    return $axios.get(`${resource}/notes/${leadId}`)
  },
  postNote(params) {
    return $axios.post(`${resource}/notes`, params)
  },
  postUpdateNote(params) {
    return $axios.post(`${resource}/notes/update`, params)
  },
  postDeleteNote(params) {
    return $axios.post(`${resource}/notes/delete`, params)
  },
})
