import { useRequestOffice } from '~/repositories'

const resource = '/api/v3/mentor'

export default () => ({
  getUserLearningPath(talentId) {
    return useRequestOffice(
      'get',
      `${resource}/custom-learning-path/${talentId}`
    )
  },
  patchSaveLearningPath(talentId, activities) {
    return useRequestOffice(
      'patch',
      `${resource}/custom-learning-path/${talentId}`,
      {
        user_activities_process_stages: activities,
      }
    )
  },
})
