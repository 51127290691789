const initialState = {
  rejected: {
    data: [],
    paginate: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
  },
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_REJECT(state, { type, value }) {
    state[type] = value
  },
  DELETE_ELEMENT_REJECT(state, id) {
    const indexDelete = state.rejected.data.findIndex((item) => item.id === id)

    if (indexDelete >= 0) {
      state.rejected.data.splice(indexDelete, 1)
      state.rejected.paginate.total -= 1
    }
  },
}

export const actions = {
  async fetchRejected({ commit }, params) {
    const response =
      await this.$repository.v3.partnership.offersKanban.getRejectedOffers(
        params
      )

    if (!response.success) {
      commit('SET_STATE_REJECT', {
        type: 'rejected',
        value: { ...initialState.rejected },
      })
    }

    if (response.success) {
      const { result } = response.result
      const dataSearch = {
        data: result.data,
        paginate: {
          current_page: result.meta.current_page,
          last_page: result.meta.last_page,
          total: result.meta.total,
          per_page: result.meta.per_page,
        },
      }

      commit('SET_STATE_REJECT', {
        type: 'rejected',
        value: dataSearch,
      })
    }

    return response
  },
}
