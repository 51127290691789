import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/mentor/deliverables'

export default () => ({
  getPendingRevisions(params) {
    return useRequestOffice('get', `${resource}/pending-revisions`, { params })
  },
  getPendingDeliverables(params) {
    return useRequestOffice('get', `${resource}/pending`, { params })
  },
})
