import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/courses/'

export default () => ({
  getCourses(params) {
    return useRequestOffice('get', `${resource}/lista-de-cursos`, { params })
  },
  deleteCourse(id) {
    return useRequestOffice('delete', `${resource}/eliminar-curso/${id}`)
  },
  postCourse(newCourse) {
    return useRequestOffice('post', `${resource}/crear-curso`, newCourse)
  },
  putCourse(id, newCourse) {
    return useRequestOffice(
      'put',
      `${resource}/actualizar-curso/${id}`,
      newCourse
    )
  },
  getDeliverables(params) {
    return useRequestOffice('get', `${resource}/lista-de-entregables`, {
      params,
    })
  },
  deleteDeliverable(id) {
    return useRequestOffice('delete', `${resource}/eliminar-entregble/${id}`)
  },
  postDeliverable(newDeliverable) {
    return useRequestOffice(
      'post',
      `${resource}/crear-entregable`,
      newDeliverable
    )
  },
  putDeliverable(id, newDeliverable) {
    return useRequestOffice(
      'put',
      `${resource}/actualizar-entregable/${id}`,
      newDeliverable
    )
  },
  getGroupSessions(params) {
    return useRequestOffice('get', `${resource}/lista-de-sesiones-grupales`, {
      params,
    })
  },
  deleteGroupSession(id) {
    return useRequestOffice(
      'delete',
      `${resource}/eliminar-sesion-grupal/${id}`
    )
  },
  postGroupSession(newGroupSession) {
    return useRequestOffice(
      'post',
      `${resource}/crear-sesion-grupal`,
      newGroupSession
    )
  },
  putGroupSession(id, newGroupSession) {
    return useRequestOffice(
      'put',
      `${resource}/actualizar-sesion-grupal/${id}`,
      newGroupSession
    )
  },

  // TALENTS SIN GRUPO
  getListTalentsWithoutGroup(params) {
    return useRequestOffice(
      'get',
      `${resource}/lista-de-talents-sin-session-grupal`,
      { params }
    )
  },
  putGroupSessionTalent(id, content) {
    return useRequestOffice(
      'put',
      `${resource}/asignar-session-grupal/${id}`,
      content
    )
  },
  // DETALLE DE UNA SESIÓN GRUPAL
  getGroupSessionsById(id) {
    return useRequestOffice(
      'get',
      `${resource}/lista-de-la-sesion-grupal-seleccionada/${id}`
    )
  },
  // SEMANAS DE LA SESIÓN GRUPAL
  getListWeek(params) {
    return useRequestOffice('get', `${resource}/lista-de-semanas`, { params })
  },
  deleteWeek(id) {
    return useRequestOffice('delete', `${resource}/eliminar-semana/${id}`)
  },
  postWeek(newWeek) {
    return useRequestOffice('post', `${resource}/crear-semana`, newWeek)
  },
  putWeek(id, newWeek) {
    return useRequestOffice(
      'put',
      `${resource}/actualizar-semana/${id}`,
      newWeek
    )
  },
  // TALENTS DE LA SESIÓN GRUPAL
  getListTalents(params) {
    return useRequestOffice('get', `${resource}/lista-de-talents`, { params })
  },
  deleteTalent(id) {
    return useRequestOffice('delete', `${resource}/eliminar-talents/${id}`)
  },
  postTalent(newWeek) {
    return useRequestOffice('post', `${resource}/crear-talents`, newWeek)
  },
  putTalent(id, newWeek) {
    return useRequestOffice(
      'put',
      `${resource}/actualizar-talents/${id}`,
      newWeek
    )
  },
})
