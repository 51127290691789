import moment from 'moment'
import { notification } from 'ant-design-vue'
import status from '~/assets/data/user_status.json'
import languageProficiencies from '~/assets/data/language_proficiencies.json'

export const state = () => ({
  talent_id: null,

  profile_basic: null,
  profile_basic_id: null,

  profile_complete: null,
  profile_complete_id: null,

  risks: null,

  progress: null,
  streak: null,
  stages: null,
  missions: null,
  view_placed: false,
  countries: [],
  moneys: [],
})

export const getters = {
  getActivatedAt: (state) => (format) => {
    if (!state.profile_basic) return null

    const date = state.profile_basic.activated_at
    if (!date) return null
    if (format) return moment(date).format(format)
    return date
  },
  getUserId: (state) => {
    if (!state.profile_basic) return null
    return state.profile_basic.id
  },
  getGender: (state) => {
    if (!state.profile_basic) return '-'
    if (!state.profile_basic.profile) return '-'
    if (!state.profile_basic.profile.sexo) return '-'
    if (state.profile_basic.profile.sexo === 1) return 'Masculino'
    return 'Femenino'
  },
  getLevelEnglish: (state) => (id) => {
    if (!state.profile_basic) return '-'
    if (!state.profile_basic.profile) return '-'
    if (!state.profile_basic.profile.english_level) return '-'
    const level = languageProficiencies.find((item) => item.id === id)
    if (level) return level.name
    return '-'
  },
  getDatePlacement: (state) => (format) => {
    const userPlacement =
      (state.profile_basic && state.profile_basic.user_status_date_placement) ||
      null

    if (!userPlacement) return null
    if (format) return moment(userPlacement).format(format)
    return userPlacement
  },
  getRiskActual: (state) => {
    const risk = state.profile_basic.risk_level_id
    if (!risk) return '?'
    const riskActual = state.risks.find((item) => item.id === risk)
    return riskActual ? riskActual.name : '?'
  },
  getNameMentor: (state) => {
    if (!state.profile_basic) return '-'

    const mentors = state.profile_basic.mentors
    if (mentors.length <= 0) return '-'
    return state.profile_basic.mentors[0].name || '-'
  },
  getCountMentoring: (state) => {
    if (!state.profile_basic) return null
    return state.profile_basic.mentoring_session_credits
  },
  getProfile: (state) => {
    if (!state.profile_basic) return null
    if (!state.profile_basic.profile) return null
    if (!state.profile_basic.profile.developer_type) return null
    return state.profile_basic.profile.developer_type.name
  },
  getStatusName: (state) => {
    const findStatus = status.find(
      (item) => item.id === state.profile_basic.user_status_id
    )
    return findStatus.name
  },
  getScore: (state) => (label, value) => {
    if (!state.profile_basic) return null
    const score = state.profile_basic.score.find((item) => item.label === label)
    if (score) {
      if (value) return score.pivot.score
      return score
    }
    return null
  },
}

export const mutations = {
  SET_STATE_PROFILE(state, { type, data }) {
    state[type] = data
  },
  SET_RISK_PROFILE(state, payload) {
    if (state.profile_basic) state.profile_basic.risk_level_id = payload

    if (state.profile_complete)
      state.profile_complete.profile.risk_level_id = payload
  },
  SET_PROFILE_MENTORING(state, payload) {
    if (state.profile_basic)
      state.profile_basic.mentoring_session_credits = payload
  },
  SET_STATUS_PROFILE(state, payload) {
    if (state.profile_basic) state.profile_basic.user_status_id = payload

    if (state.profile_complete)
      state.profile_complete.profile.user_status_id = payload
  },
  SET_ONBOARDING(state, payload) {
    if (state.profile_basic)
      state.profile_basic.onboarding_session = payload.value

    if (state.profile_complete)
      state.profile_complete.profile.onboarding_session = payload.value
  },
  SET_CONTRACT(state, payload) {
    if (state.profile_basic) state.profile_basic.contract_status = payload

    if (state.profile_complete)
      state.profile_complete.profile.contract_status = payload
  },
  SET_ACTIVATED_AT(state, payload) {
    if (state.profile_basic) state.profile_basic.activated_at = payload

    if (state.profile_complete)
      state.profile_complete.profile.activated_at = payload
  },
  SET_VIEW_PLACED(state, payload) {
    state.view_placed = payload
  },
  SET_STATE_COUNTRIES(state, { type, data }) {
    state[type] = data
  },
  SET_STATE_MONEYS(state, { type, data }) {
    state[type] = data
  },
  SET_STATUS_PROFILE_BASIC(state, statusId) {
    state.profile_basic.user_status_id = statusId
  },
}

export const actions = {
  getProfileBasic({ commit }, id) {
    return this.$repository.mentor.userProfile
      .profileBasic({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'profile_basic_id',
          data: id,
        })

        commit('SET_STATE_PROFILE', {
          type: 'profile_basic',
          data: data.data.profile,
        })

        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getProfile({ commit }, id) {
    return this.$repository.mentor.userProfile
      .profileComplete(id)
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'profile_complete_id',
          data: id,
        })

        commit('SET_STATE_PROFILE', {
          type: 'profile_complete',
          data: data.data,
        })

        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  updateProfile({ dispatch, state }, payload) {
    return this.$repository.mentor.profile
      .postUpdateProfile(payload)
      .then(async ({ data }) => {
        await dispatch('getProfileBasic', state.profile_basic.id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getRisks({ commit }) {
    return this.$repository.mentor.userProfile
      .postAllRisks()
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'risks',
          data: data.data,
        })
        return data
      })
  },
  saveRisks({ state, commit }, level) {
    return this.$repository.mentor.userProfile
      .postUpdateRisk({
        user_id: state.profile_basic.id,
        risk_level_id: level,
      })
      .then(({ data }) => {
        commit('SET_RISK_PROFILE', data.data.risk_level_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  saveStatus({ commit, dispatch }, payload) {
    return this.$repository.mentor.userProfile
      .postUpdateStatus(payload)
      .then(async ({ data }) => {
        commit('SET_STATUS_PROFILE', payload.user_status_id)
        commit(
          'mentor/talents/SET_STATUS_TALENTS',
          { userID: data.data.id, userStatusId: data.data.user_status_id },
          {
            root: true,
          }
        )
        await dispatch('getProfileBasic', payload.user_id)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  changeOnboarding({ commit }, params) {
    return this.$repository.mentor.userProfile
      .updateOnboarding(params)
      .then((res) => {
        const {
          data: { date },
        } = res.data
        commit('SET_ONBOARDING', {
          user_id: date.id,
          value: date.onboarding_session ? 1 : 0,
        })
        commit(
          'mentor/talents/SET_ONBOARDING',
          {
            user_id: date.id,
            value: date.onboarding_session ? 1 : 0,
          },
          { root: true }
        )
        return res.data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getProgress({ commit }, id) {
    return this.$repository.mentor.userProfile
      .postGetProgress({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'progress',
          data: data.data,
        })
        commit('SET_STATE_PROFILE', {
          type: 'talent_id',
          data: id,
        })
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getStreak({ commit }, id) {
    return this.$repository.mentor.userProfile
      .postGetStreak({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'streak',
          data: data.data,
        })
        commit('SET_STATE_PROFILE', {
          type: 'talent_id',
          data: id,
        })
        return data
      })
  },
  getStages({ commit }, id) {
    return this.$repository.mentor.userProfile
      .postGetStages({
        user_id: id,
      })
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'stages',
          data: data.data,
        })
        commit('SET_STATE_PROFILE', {
          type: 'talent_id',
          data: id,
        })
        return data
      })
  },
  saveContract({ commit }, payload) {
    return this.$repository.mentor.v2.userProfile
      .postSaveContract(payload.id, payload.val)
      .then(({ data }) => {
        commit('SET_CONTRACT', data.data.contract_status)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  getMissions({ commit }, talentId) {
    return this.$repository.mentor.v2.userProfile
      .getMissions(talentId)
      .then(({ data }) => {
        commit('SET_STATE_PROFILE', {
          type: 'missions',
          data: data.data,
        })
        commit('SET_STATE_PROFILE', {
          type: 'talent_id',
          data: talentId,
        })
        return data
      })
  },

  updateActivatedAt({ commit }, payload) {
    return this.$repository.mentor.userProfile
      .postUpdateActivatedAt({
        user_id: payload.userId,
        activated_at: payload.activatedAt,
      })
      .then(({ data }) => {
        commit('SET_ACTIVATED_AT', data.data.activated_at)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },

  getAllCountries({ commit }) {
    return this.$repository.mentor.userProfile
      .getAllCountries()
      .then(({ data }) => {
        commit('SET_STATE_COUNTRIES', {
          type: 'countries',
          data: data.data,
        })
        return data
      })
  },

  getAllTypesMoney({ commit }) {
    return this.$repository.mentor.userProfile
      .getAllTypesMoney()
      .then(({ data }) => {
        commit('SET_STATE_MONEYS', {
          type: 'moneys',
          data: data.data,
        })
        return data
      })
  },
}
