const resource = '/mentor/v2/user_profile'

export default ($axios) => ({
  postGetDataContract(id) {
    return $axios.post(`${resource}/${id}/contract-info`)
  },
  postSaveDataContract(id, params) {
    return $axios.post(`${resource}/${id}/edit-contract-info`, params)
  },
  postSaveContract(id, val) {
    return $axios.post(`${resource}/${id}/contract`, {
      contract_status: val,
    })
  },

  postGetDeliverables(payload) {
    return $axios.post(`${resource}/deliverables`, payload)
  },

  getMissions(talentId) {
    return $axios.get(`${resource}/${talentId}/missions`)
  },
})
