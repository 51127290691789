import { useRequestOffice } from '~/repositories'
const resource = '/api/v3/admin/objectives'

export default () => ({
  getActivities(id) {
    return useRequestOffice('get', `${resource}`, {
      params: { mission_id: id },
    })
  },
  getObjectives(params) {
    return useRequestOffice('get', `${resource}`, { params })
  },
  deleteObjective(id) {
    return useRequestOffice('delete', `${resource}/${id}`)
  },
  postObjective(newObjective) {
    return useRequestOffice('post', `${resource}`, newObjective)
  },
  putObjective(id, newObjective) {
    return useRequestOffice('put', `${resource}/${id}`, newObjective)
  },
})
