import { notification } from 'ant-design-vue'

const initialState = {
  followUps: [],
  status: [],
  followUp: null,
  notes: [],
  applicant: {},
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE(state, { type, value }) {
    state[type] = value
  },
  UPDATE_APPLICANT_NOTE(state, payload) {
    const indexUpdate = state.followUps.findIndex(
      (item) => item.id === payload.applicant_id
    )
    const applicant = state.followUps[indexUpdate]
    const indexNoteUpdate = applicant.followup_notes.findIndex(
      (item) => item.id === payload.id
    )
    if (indexNoteUpdate >= 0) {
      applicant.followup_notes[indexNoteUpdate] = payload
    } else {
      applicant.followup_notes = [payload]
    }
    if (applicant.followup_status_id === 1) {
      applicant.followup_status_id = 2
    }
    state.followUps.splice(indexUpdate, 1, applicant)
  },
  DELETE_APPLICANT_NOTE(state) {
    const indexUpdate = state.followUps.findIndex(
      (item) => item.id === state.applicant.id
    )

    if (indexUpdate >= 0) {
      state.followUps[indexUpdate].followup_notes = state.notes
    }
  },
  ADD_NOTE(state, { value }) {
    state.notes.unshift(value)
  },
  UPDATE_NOTE(state, payload) {
    const indexUpdate = state.notes.findIndex((item) => item.id === payload.id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1, payload)
    }
  },
  DELETE_NOTE(state, id) {
    const indexUpdate = state.notes.findIndex((item) => item.id === id)

    if (indexUpdate >= 0) {
      state.notes.splice(indexUpdate, 1)
    }
  },
}

export const actions = {
  getFollowUpList({ commit }, params) {
    return this.$repository.v3.admission.applicants.followUp
      .getFollowUpList(params)
      .then((res) => {
        commit('SET_STATE', {
          type: 'followUps',
          value: res.data.result.list.data,
        })
        return res.data.result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  getApplicant({ commit }, params) {
    commit('SET_STATE', {
      type: 'applicant',
      value: params,
    })
  },

  // Notes
  getNotes({ commit }, applicantId) {
    commit('SET_STATE', {
      type: 'notes',
      value: [],
    })
    return this.$repository.v3.admission.applicants.followUp
      .getNotes(applicantId)
      .then((res) => {
        commit('SET_STATE', {
          type: 'notes',
          value: res.data.result,
        })
        return res.data.result
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  createNote({ commit }, params) {
    return this.$repository.v3.admission.applicants.followUp
      .postCreateNote(params)
      .then((res) => {
        commit('ADD_NOTE', {
          value: res.data.result.note,
        })
        commit('UPDATE_APPLICANT_NOTE', res.data.result.note)
        return {
          status: res.status,
          data: res.data.success,
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.data.message,
        })
        return err
      })
  },
  updateNote({ commit }, payload) {
    return this.$repository.v3.admission.applicants.followUp
      .postUpdateNote(payload)
      .then(({ data }) => {
        commit('UPDATE_NOTE', data.result.note)
        commit('UPDATE_APPLICANT_NOTE', data.result.note)
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
  deleteNote({ commit }, payload) {
    return this.$repository.v3.admission.applicants.followUp
      .postDeleteNote(payload)
      .then(({ data }) => {
        commit('DELETE_NOTE', payload.note_id)
        commit('DELETE_APPLICANT_NOTE')
        return data
      })
      .catch(({ data }) => {
        notification.error({
          message: 'Error',
          description: data.message,
        })
        return data
      })
  },
}
