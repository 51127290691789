
import esES from 'ant-design-vue/lib/locale-provider/es_ES'

import { mapState } from 'vuex'
import LogoAdmin from '~/components/layout/LogoAdmin'
import ButtonCollapse from '~/components/layout/ButtonCollapse'
import AdminMenu from '~/components/layout/AdminMenu'
import UserMenu from '~/components/layout/UserMenu.vue'
import GlobalSearch from '~/components/layout/global-search/GlobalSearch.vue'

export default {
  name: 'Default',
  components: {
    GlobalSearch,
    UserMenu,
    AdminMenu,
    ButtonCollapse,
    LogoAdmin,
  },
  data: () => ({
    locale: esES,
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState(['collapsedSidebar']),
  },
}
