const resource = '/api/v3/mentor'

export default ($axios) => ({
  getJobsSourcing(params) {
    return $axios.get(`${resource}/jobs-sourcing`, { params })
  },
  createJobsSourcing(params) {
    return $axios.post(`${resource}/jobs-sourcing`, params)
  },
  updateJobsSourcing(id, params) {
    return $axios.put(`${resource}/jobs-sourcing/${id}`, params)
  },
  deleteJobsSourcing(id) {
    return $axios.delete(`${resource}/jobs-sourcing/${id}`)
  },
})
