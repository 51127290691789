
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DeliverablesCount',
  computed: {
    ...mapGetters('mentor/deliverables', ['getPendingsCount']),
  },
  async mounted() {
    await this.getDeliverablesByMentor()
  },
  methods: {
    ...mapActions('mentor/deliverables', ['getDeliverablesByMentor']),
  },
}
