import { datadogLogs } from '@datadog/browser-logs'

export default function ({ $config }) {
  const { datadog } = $config

  const init = {
    clientToken: datadog.clientToken,
    site: datadog.site,
    service: datadog.service,
    env: datadog.env,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version: datadog.version,
  }
  if (datadog.enabled) {
    datadogLogs.init(init)
  }
}
