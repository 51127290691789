import debugEvents from '~/tracking/events/debugEvents'
import userEvents from '~/tracking/events/userEvents'
import offerEvents from '~/tracking/events/offerEvents'
import admissionEvents from '~/tracking/events/admissionEvents'

export default (ctx, inject) => {
  inject('tracking', {
    user: userEvents(ctx),
    admission: admissionEvents(ctx),
    offer: offerEvents(ctx),
    debug: debugEvents(ctx),
  })
}
