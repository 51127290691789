
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ButtonCollapse',
  computed: {
    ...mapState(['collapsedSidebar']),
  },
  methods: {
    ...mapMutations(['TOGGLE_COLLAPSED']),
  },
}
